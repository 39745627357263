import React from 'react';
import CSSModules from 'react-css-modules'
import styles from './ListHeader.css'
import { transS } from '../../../../services/helpers/lang';

const ListHeader = params => {
    let {currentOption, user} = params; 
    const tabs = [transS('Projects'),transS('Templates'),
      transS('Company Administration'), transS("Clause Library")];
    const refs = ['/','/templates', '/company', '/clauses'];
    const element = no =>
    <a styleName="element" data-current={no === currentOption} key={no}
       href={no === currentOption ? '#' : refs[no]}>
            {tabs[no]}
    </a>
    
    const isCompanyAdmin = user.company?.knownCompanies[0].members._general.members.find(m => user._id === m._id)?.accessStatus > 0;
    let result = [element(0), element(1)];
    if (isCompanyAdmin) {
        result.push(element(2));
        result.splice(1,0,element(3));
    }
    return (
    <div styleName="wrapper">
        {result}
        <div styleName="line"></div>
    </div>);
};
export default CSSModules(ListHeader, styles, {allowMultiple: true});



