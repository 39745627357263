import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './appRoot.css';
import Spinner from '../components/spinner/Spinner';

import { checkLogin } from '../services/actions/userActions';

import PrivateArea from './privateArea/PrivateArea';
import PublicArea from './publicArea/PublicArea';

import PopupWindows from '../components/popupWindows/PopupWindows';
import PopupWithInput from '../components/popupWindows/PopupWithInput';
import BrowserSupportBlockAlert from "../components/browserSupport/BrowserSupportBlockAlert"
import DeactivatedNotice from "../components/deactivatedNotice/DeactivatedNotice"
import SendPopup from "../components/popupWindows/SendPopup"
import AuthPopup from "../components/popupWindows/AuthPopup"
import {
  checkBrowserSupport, isNeedNotFullyBrowserSupportAlert, notFullySupportBrowserPopup
} from '../components/browserSupport/browsersCheck'
import {getPopUpAction} from '../services/actions/dataActions'
import {popUpReset} from '../services/helpers'
import EditTemplatePopup from '../components/popupWindows/EditTemplatePopup'

class AppRoot extends Component {
  async componentDidMount() {
    let { checkLogin } = this.props;
    try {
      await checkLogin();
    } catch (err) {
      return false;
    }
  }

    render() {
    let { user, firstTime, isLoaded, popUp, popUpWithInput, activationInProgress, 
        loader, getPopUpAction, isEditTemplatePopupOpen } = this.props;

    return (
      <div styleName='wrapper'>
        {
          (() => {
            if (!checkBrowserSupport()) return <BrowserSupportBlockAlert />
            if (user && user._id && !loader) {
              if (isNeedNotFullyBrowserSupportAlert() && (!popUp || !popUp.name || popUp.name === 'popUpReset')) {
                getPopUpAction(notFullySupportBrowserPopup(() => getPopUpAction(popUpReset)))
              }
              if (user.deactivated) return <DeactivatedNotice />
              return <PrivateArea user={user} firstTime={firstTime} />;
            } else if (activationInProgress || (isLoaded && (!user || !user._id) && !loader)) {
              return <PublicArea />;
            } else {
              return <Spinner />;
            }
          })()
        }

        {popUp && popUp.name === 'confirm' && <PopupWindows />}
        {popUp && popUp.name === 'send' && <SendPopup />}
        {popUp && popUp.name === 'auth' && <AuthPopup />}
        {popUpWithInput && popUpWithInput.name === 'rename' && <PopupWithInput />}
        {isEditTemplatePopupOpen && <EditTemplatePopup />}
      </div>
    );
  }
}

AppRoot.propTypes = {
  user: PropTypes.object,
  isLoaded: PropTypes.bool,
  popUp: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  popUpWithInput: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  invitePopup: PropTypes.object,
  activationInProgress: PropTypes.bool
};

export default withRouter(connect(state => ({
  user: state.userReducer.user,
  firstTime: state.userReducer.firstTime,
  projectList: state.projectReducer.projectList,
  isLoaded: state.userReducer.isLoaded,
  popUp: state.dataReducer.popUp,
  popUpWithInput: state.dataReducer.popUpWithInput,
  invitePopup: state.projectReducer.invitePopup,
  loader: state.dataReducer.loader,
  activationInProgress: state.userReducer.activationInProgress,
  isEditTemplatePopupOpen: state.templateReducer.isEditPopupOpen
}), {
  getPopUpAction,
  checkLogin,
})(new CSSModules(AppRoot, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })));
