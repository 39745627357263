let current = 'en-En', pos = -1
const langPositions = ['ru-Ru']

export const setLanguage = l => {
    current = l
    pos = langPositions.findIndex(el => el === l)
}
export const getLanguage = () => pos
const allocName = document.title
export const allocSimplawyer = allocName === 'Simplawyer.one'
export const publicHost = allocSimplawyer ?
  'https://www.simplawyer.one/' : 'https://www.contract.one'
export const signingTool = () =>  !allocSimplawyer ? 'Docusign' : null
export const canTariffed = () =>  !allocSimplawyer

const simpleDict = {
    'For text': ['К тексту'],
    'For all document': ['Ко всему документу'],
    // Дефолтные стили, которые переводятся в ворде автоматически
    'Normal': ['Обычный'],
    'heading 1': ['заголовок 1'],
    'heading 2': ['заголовок 2'],
    'heading 3': ['заголовок 3'],
    'heading 4': ['заголовок 4'],
    'heading 5': ['заголовок 5'],
    'heading 6': ['заголовок 6'],
    'heading 7': ['заголовок 7'],
    'heading 8': ['заголовок 8'],
    'heading 9': ['заголовок 9'],
    'Title': ['Заголовок'],
    'Subtitle': ['Подзаголовок'],
    'Styles': ['Стили'],
    'Quote': ['Цитата'],
    'List Paragraph': ['Абзац списка'],
    'Intense Quote': ['Интенсивная цитата'],
    'Less styles': ['Меньше стилей'],
    'More styles': ['Больше стилей'],
    'Paragraph': ['Абзац'],
    'Less options': ['Меньше опций'],
    'More options': ['Больше опций'],
    'Align': ['Выравнивание'],
    'Before': ['Интервал перед'],
    'Hanging': ['Выступ'],
    'First Line': ['Первая строка'],
    'Left': ['Отступ слева'],
    'Right': ['Отступ справа'],
    'Line Spacing': ['Межстрочный интервал'],
    'cm': ['см'],
    'pt': ['пт'],
    'After': ['Интервал после'],
    'OK': ['Понятно',],
    'Apply': ["Применить"],
    'Only Used Here': ["Используемые здесь"],
    'Account': ['Аккаунт',],
    'You': ['Вы',],
    'Upload an image': ['Загрузить изображение',],
    'Square image 80х80 px': ['Размер изображения 80х80 px',],
    'Save Changes': ['Сохранить изменения',],
    'Your Company': ['Ваша компания',],
    'Confirm Email' :['Подтвердить адрес электронной почты'],
    'Go to Company Administration': ['Перейти в управление компанией',],
    'Your account is not linked to any company yet.': ['Ваш аккаунт пока не привязан ни к одной компании.',],
    'Create Company': ['Создать компанию',],
    'Join Company': ['Присоединиться к компании',],
    'Document is too big': ['Размер загружаемого документа превышает ограничение'],
    'Upload error': ['Ошибка загрузки документа'],
'Send a request to join an existing company.': ['Отправить запрос на присоединение к уже созданной компании.',],
'To create a company:': ['Чтобы создать компанию:',],
'Upgrade to Basic Plan': ['Перейти на Базовый тариф',],
'Your Plan': ['Ваш тариф',],
'Demo': ['Демо',],
'Send now': ['Отправить сейчас',],
'Basic (Expired)': ['Оплата просрочена',],
'Paid until': ['Оплачено до',],
'Renew Plan': ['Продлить тарифный план',],
'Upgrade Plan': ['Обновить тарифиный план',],
'Upgrade': ['Обновить',],
'Free': ['Бесплатно',],
'Password': ['Пароль',],
'Current Password': ['Текущий пароль',],
'New Password': ['Новый пароль',],
'Repeat New Password': ['Повторите новый пароль',],
'Time Settings': ['Настройки времени',],
'Time Zone': ['Часовой пояс',],
'Auto': ['Авто',],
'Time Format': ['Формат времени',],
'12h': ['12 ч',],
'24h': ['24 ч',],
'30 min': ['30 мин',],
'1 hr': ['1 час',],
'2 hrs': ['2 часа',],
'4 hrs': ['4 часа',],
'1 day': ['1 день',],
'2 days': ['2 дня',],
'3 days': ['3 дня',],
'1 week': ['Неделя',],
'Hide': ['Скрыть',],
'No Highlight': ['Без заливки'],
'Hide This': ['Скрыть',],
'Hide All': ['Скрыть все',],
'Un-': [' ',],
'Log Out': ['Выйти',],
'Projects': ['Проекты',],
'Templates': ['Шаблоны',],
'Company Administration': ['Управление компанией',],
'Projects and Documents': ['Проекты и документы',],
'New Project': ['Новый проект',],
'Find:': ['Поиск:',],
'Search Fields': ['Расширенный поиск',],
'Search Fields ': ['Поиск',],
'...searching': ['...идет поиск',],
'No documents found.': ['Документы не найдены',],
'Clear Search': ['Очистить',],
'Sort by:': ['Сортировать по:',],
'Party': ['Контрагент',],
'Project': ['Проект',],
'Date': ['Дата'],
'Date Modified': ['Дата изменения',],
'Unstructured': ['Неструктурированный',],
'Companies, Projects and Documents': ['Компании, проекты и документы',],
'Requires Attention': ['Требует внимания',],
'Activity in the Document': ['Действия в документе',],
'Current Stage': ['Текущий этап',],
'Unspecified Companies': ['Компания не указана',],
'Go to all projects': ['Перейти ко всем проектам',],
'Go to Templates': ['Перейти к шаблонам',],
'Draft': ['Черновик',],
'Internal Review & Approval': ['Внутренняя проверка и согласование',],
'Internal Review': ['Внутренняя проверка',],
'External Review': ['Внешняя проверка',],
'Your Approval Required': ['Требуется ваше согласование',],
'change': ['изменение',],
'changes': ['изменения',],
'Signed Version': ['Подписанная версия',],
'Declined to sign':['Подписание отклонено'],
'Signing in Progress': ['Выполняется подписание'],
'Collaboration': ['Совместная работа',],
'Shared': ['В общем доступе',],
'since': ['с'],
'Now being edited by': ['Сейчас редактируется'],
' both teams': [' обеими командами'],
' our team': [' нашей командой'],
' other team': ['другой командой'],
'Finalised': ['Финализировано',],
'Finalised by everyone': ['Финализировано всеми',],
'Finalised on your side': ['Финализировано с вашей стороны',],
'The other team requested to cancel Final Version': ['Другая команда просит отменить финальную версию',],
'Your team requested to cancel Final Version': ['Ваша команда просит отменить финальную версию',],
'Refuse to Cancel <br/> Final Version':  ['Отказать в отмене <br/>финальной версии',],
'Cancel <br/> this request': ['Отменить<br/>запрос'],
'The External Team did not finalise the document': ['Другая команда не финализировала документ',],
'Cancel Final Version': ['Отменить финальную версию',],
'Status': ['Статус',],
'Statistics': ['Статистика',],
'Created on': ['Создан',],
'Created by': ['Создатель',],
'Help': ['Помощь',],
'Brief Tutorial': ['Краткое руководство',],
'Home page': ['Главная страница',],
'Project Setup': ['Настройки проекта',],
'No Documents Yet': ['Ещё нет документов',],
'Document View': ['Просмотр документа',],
'Toolbar': ['Инструменты',],
'Document name': ['Имя документа',],
'Document Name': ['Название документа',],
'Folder name': ['Имя папки',],
'Rename Folder': ['Переименовать папку',],
'Rename Document': ['Переименовать документ',],
'Delete Folder': ['Удалить папку',],
'Delete Document': ['Удалить документ',],
'This comment has been removed.': ['Комментарий был удален',],
'Jump to the first item': ['Перейти к первому',],
'Jump to the next item': ['Перейти к следующему',],
'Show the deleted item': ['Показать удаленный',],
'Do you want to remove the project?': ['Вы желаете удалить проект?',],
'Do you want to remove the document?': ['Вы желаете удалить документ?',],
'Do you want to remove the folder?': ['Вы желаете удалить папку?',],
'Editing': ['Редактирование',],
'Email Support': ['Техподдержка',],
'5 min Video Overview': ['5-минутный видеообзор',],
'Get Started': ['Начать работу',],
'I understand': ['Я понимаю',],
'Cancel Request': ['Отменить запрос'],
'Are you sure you want to cancel this request?': ['Вы уверены, что хотите отменить этот запрос?'],

'Take a quick tour': ['Быстрый обзор',],
'Let’s Get Started!': ['Поехали!',],
'Start a new project': ['Начать проект',],


'Showing Version': ['Версия',],

'Comments': ['Комментарии',],
'New Internal Threads': ['Новое внутреннее<br>обсуждение',],
'New External Threads': ['Новое внешнее<br>обсуждение',],
'You cannot resolve a thread with an unsent message': ['Вы не можете завершить обсуждение с неотправленным комментарием',],
'More': ['Ещё',],
'Start New Thread.': ['Написать сообщение',],
'@ to mention': ['@ чтобы упомянуть',],
'Internal': ['Внутренний',],
'External': ['Внешний',],
'Personal': ['Личный',],
' (Resolved)': [' (Завершен)'],
'Choose Team Member': ['Выберите участника команды',],
'Type name or email': ['Введите имя или имейл',],
'Select a person': ['Выберите участника команды',],
'Personal Message': ['Личное сообщение',],
'Add': ['Добавить',],
'Collapse This': ['Свернуть это',],
'Collapse All': ['Свернуть всё',],
'Expand This': ['Развернуть это',],
'Expand All': ['Развернуть всё',],
'Communication': ['Коммуникация',],
'Sort': ['Сортировать',],
'New First': ['Сначала новые',],
'Original Order': ['Исходный порядок',],
'View': ['Вид',],
'Formatting': ['Формат',],
'Approve': ['Согласовать',],
'Request Approval': ['Запросить согласование',],
'Cancel Approval': ['Отменить согласование',],
'Not approved yet': ['Ещё не согласовано',],
"Request Everyone's Approval": ['Запросить согласование у всех',],
'Approved': ['Согласовано',],
' on ': [' ',],
'Cancelled Approval': ['Согласование отменено',],
'You Approved': ['Вы согласовали',],
'Approval cancelled by new changes': ['Согласование было отменено из-за новых правок',],
'Your Approval <br/> was cancelled <br/> by new changes': ['Ваше согласование<br/>было отменено<br/>из-за новых правок',],
'Approve Again': ['Согласовать снова',],
'Upload or Create from Another Template': ['Загрузить или создать из шаблона',],
'Approval Request': ['Запрос на согласование',],
'Send': ['Отправить',],
'Send to Counterparty': ['Отправить контрагенту',],
'Send with Message': ['Отправить с сообщением',],
'Sending Document': ['Отправка документа',],
'You are about to send this document together with external comments to the other party.': ['Этот документ отправится другой стороне вместе с внешними комментариями',],
'Approvals Needed': ['Требуются согласования',],
'Before you can send you need the document to be approved by:': ['Прежде чем отправить, вам нужно, чтобы документ был согласован:',],
'Changes without attention': ['Правки, которые остались без внимания',],
'Who is going to receive': ['Кто получит документ',],
'Are you sure you want to send?': ['Вы уверены, что хотите отправить?',],
'Wait': ['Подождать',],
'No': ['Нет',],
'Send anyway': ['Всё равно отправить',],
'Show Tracked Changes?': ['Показать правки?',],
'Yes, show': ['Да, показать',],
'No, send clean': ['Нет, отправить чистым',],
'Being reviewed by Counterparty': ['На рассмотрении у контрагента',],
'Sent: a few seconds ago. Not opened yet': ['Отправлено: несколько секунд назад.  Ещё не открывали',],
'Request Back': ['Запросить обратно',],
'The other team will get a request from you. They will send the document when they are ready.': ['Другая команда получит от вас запрос. Они отправят документ, когда будут готовы.',],
'You can ask the other team to send you their version.': ['Вы можете попросить другую команду отправить вам их версию.',],
'Request': ['Запросить',],
'Take Over Editing': ['Перехватить редактирование',],
'Add Message': ['Добавить сообщение',],
'Overtake Editing': ['Перехватить редактирование',],
'Do you really want to take the control over editing?': ['Вы действительно хотите взять на себя контроль редактирования?',],
'Overtake': ['Взять контроль',],
'Cancel': ['Отмена',],
'Approvals History': ['История согласований',],
'Approvals': ['Согласования',],
'Collaborate with Counterparty': ['Редактировать одновременно с другой стороной',],
'Collaborate': ['Редактировать одновременно ',],
'Finalise Document': ['Финализировать документ',],
'Start Finalising': ['Начать финализацию',],
'Before you send, export or finalise the document, you need it to be approved by:': ['Прежде чем отправить, экспортировать или финализировать документ, вам нужно, чтобы его согласовали:',],
'Finalise anyway!': ['Всё равно финализировать!',],
'Heads up!': ['Внимание!',],
'You are about to send this document for collaboration together with external comments to the other party.': ['Вы собираетесь отправить этот документ для совместной работы вместе с внешними комментариями другой стороне.',],
'Are you sure you want to start Collaboration Mode?': ['Вы уверены, что хотите начать режим совместной работы?',],
'Please approve the document': ['Пожалуйста, согласуйте документ',],
'Accept': ['Принять',],
'Decline': ['Отклонить',],
'Mute': ['Скрыть',],
'Undo Accept': ['Отменить принятие',],
'Undo Decline': ['Отменить отклонение',],
'Undo Mute': ['Отменить скрытие',],
'The document is now in collaborative mode': ['Теперь документ находится в совместной работе'],


'This paragraph is being edited by ': ['Этот абзац сейчас редактирует ',],
'Deleted by': ['Удалено',],
'on': [' ',],
'You ': ['Вами',],
' by ': [' ',],
'Declined by': ['Отклонено',],
'Accepted by': ['Принято',],
'Saving...': ['Сохраняется...',],
'Show in paragraph history': ['Показать историю изменений',],
'Pin': ['Закрепить',],
'Add Thread Title': ['Добавить заголовок',],
'Enter Thread Title': ['Введите заголовок обсуждения',],
'Type reply': ['Введите ответ',],
'Resolve': ['Завершить',],
'Unresolve': ['Восстановить обсуждение',],
'Edit': ['Редактировать',],
'Delete': ['Удалить',],
'Save': ['Сохранить',],
'Thread Resolved': ['Обсуждение завершено',],
'External Thread': ['Внешнее обсуждение',],
'Internal Thread': ['Внутреннее обсуждение',],
'External Thread Resolved': ['Внешнее обсуждение завершено',],
'Internal Thread Resolved': ['Внутреннее обсуждение завершено',],
'Cover Message for the sent version': ['Сопроводительное письмо для отправленной версии',],
'Cover Message for starting collaboration': ['Сопроводительное письмо для совместной работы',],
'Cover Message for starting finalisation': ['Сопроводительное письмо для начала финализации',],
'Cover Message for requesting the document back': ['Сопроводительное письмо для запроса документа обратно',],
'Cover Message for taking over the document': ['Сопроводительное письмо для перехвата документа',],
'Changes & View': ['Изменения и вид',],
'Showing all': ['Показать всё',],
'Clean Version': ['Без правок',],
'Simplified Redline': ['Упрощённый',],
'Full Redline': ['Все правки',],
'Show Changes': ['Показать правки',],
'In This Version': ['В этой версии',],
'Only New': ['Только новые',],
'In 2 Latest <br/>Versions': ['В двух <br/>версиях',],
"Counterparty’s <br/>and yours": ['Ваши и <br/>контрагента',],
'In All Versions': ['Во всех версиях',],
'Show Paragraphs': ['Показать абзацы',],
'With Changes Only': ['Только с изменениями',],
'Show Text': ['Показать текст',],
'Original Formatting': ['Исходное форматирование',],
'Reading Mode': ['Режим чтения',],
'Show All Paragraphs': ['Показать все абзацы',],
'Do you want to cancel collaborate with the document?': ['Вы хотите отменить совместную работу с документом?',],
'Confirm': ['Подтвердить',],
'Teams': ['Команды',],
'Internal Team': ['Внутренняя команда',],
'External Team': ['Внешняя команда',],
'Your Firm': ['Ваша компания',],
'Link copied to clipboard': ['Ссылка на приглашение скопирована в буфер обмена'],
'Link to Invite to Project': ['Приглашение в проект'],
'Link to Invite to Project as Approver': ['Приглашение в проект согласующим'],
'Search emails, names, or groups': ['Поиск по почте, имени или группе',],
'Add Members': ['Добавить участников',],
'Approver': ['Согласующий',],
'Admin': ['Админ',],
'Signee': ['Подписант',],
'Re-send Invitation': ['Отправить приглашение ещё раз',],
'Rename': ['Переименовать',],
'Done': ['Готово',],
'Upload a document': ['Загрузить документ',],
'Upload': ['Загрузить',],
'Type Email': ['Введите Email',],
'Or Create from Another Template': ['Или создать из шаблона',],
'Upload Document': ['Загрузка документа',],
'Create Project': ['Создать проект',],
'Show More': ['Развернуть список',],
'Show Less': ['Свернуть список',],
'Just Added': ['Только что добавлено',],
'Send Invites Now': ['Отправить приглашения сейчас',],
'If you don’t send, the invites will be sent': ['Если вы не отправите сейчас, приглашения будут отправлены',],
'automatically within an hour': ['автоматически в течение часа',],
'with the first version': ['с первой версией',],
'Project Settings': ['Настройки проекта',],
'Rename Project': ['Переименовать проект',],
'Delete Project': ['Удалить проект',],
'Permanently Delete Project': ['Навсегда удалить проект',],
'Manage Your Plan': ['Управление вашим тарифом',],
'Your current plan:': ['Ваш текущий тариф:',],
'"You cannot add document for free. You already have documents in your account."': ['«Вы не можете добавить документ бесплатно. В вашем аккаунте уже есть документы.»',],
'Paid for': ['Оплачено',],
'Online payment by Stripe': ['Онлайн-оплата через Stripe',],
'Checkout': ['Оформить заказ',],
'Questions': ['Вопросы',],
'Unanswered': ['Без ответа',],
'Unpublished Draft': ['Неопубликованный черновик',],
'Unpublished': ['Не опубликовано',],
'Create Document': ['Создать документ',],
'Remember': ['Запомнить',],
'The Document <br> is finalised on <br> your side!': ['Документ <br>финализирован<br>с вашей <br>стороны!',],
'Click to rename': ['Нажмите, чтобы переименовать',],
'Good': ['Хорошо',],
'Cancel Finalise': ['Отменить финализацию',],
'Collaboration mode': ['Режим совместной работы',],
'Approval required for sending the document to the next team': ['Требуется согласование для отправки документа внешней команде',],
'After the document is finalised, a signee would need to sign the document. This requires logging in your DocuSign account.': ['После финализации документа подписанту потребуется подписать документ. Для этого требуется вход в ваш аккаунт на DocuSign.',],
'Project Admin can add and delete users and send the document without approvals.': ['Админ проекта может добавлять и удалять пользователей и отправлять документ без согласования другими',],
'Templates are available for paid accounts.': ['Шаблоны доступны для платных аккаунтов',],
'Upgrade to experience the easiest way to automate your documents.': ['Обновите тариф, чтобы узнать самый простой способ автоматизировать ваши документы.',],
'New Template': ['Новый шаблон',],
'New Folder': ['Новая папка',],
'Create Folder': ['Создать папку',],
'See All for This Project': ['Посмотреть всё для этого проекта',],
'Due': ['Дедлайн',],
'in': [' ',],
'Saving': ['Сохранение',],
'Edited': ['Отредактировано',],
'Comment': ['Комментарий',],
'Are you sure, you want to delete the comment?': ['Вы уверены, что хотите удалить комментарий?',],
'Go to next paragraph <br> with changes or comments': ['Перейти к следующему абзацу<br> с правками или комментариями',],
'Go to previous paragraph <br> with changes or comments': ['Перейти к предыдущему абзацу<br> с правками или комментариями',],
'Download': ['Скачать',],
'Cannot send the document. There is nobody on the other team.': ['Невозможно отправить документ. В другой команде никого нет.',],
'Being edited by': ['Редактирует',],
'Your team': ['ваша команда',],
'both teams': ['ваша команда и контрагент',],
'Approved by out of': ['Согласовано из',],
'Show Stats': ['Статистика',],
'Mentions': ['Упоминания',],
'Navigation': ['Навигация',],
'Navigation ': ['навигация',],
'History & Versions': ['История и версии',],
'Export in .docx': ['Экспортировать в .docx',],
'This Document': ['Этот документ',],
'This project': ['Этот проект',],
'Document Statistics': ['Статистика документа',],
'Paragraphs Stats': ['Статистика абзацев',],
'Time Spent': ['Затраченное время',],
'General': ['Общее',],
'General ': ['Общая информация'],
'Paragraphs that required most attention': ['Абзацы, потребовавшие наибольшего внимания',],
'Most Edits': ['Наибольшее количество правок',],
'Most Comments': ['Наибольшее количество комментариев',],
'Most Edits and Comments': ['Наибольшее количество правок и комментариев',],
'Both': ['Все',],
'Total': ['Всего',],
'This Version': ['Эта версия',],
'Previous Version': ['Предыдущая версия',],
'Today': ['Сегодня',],
'This Week': ['На этой неделе',],
'This Month': ['В этом месяце',],
'Last Month': ['Прошлый месяц',],
'From Start': ['С самого начала',],
'Reading': ['Чтение',],
'Commenting': ['Комментирование',],
'Sent between the Parties': ['Отправлено между сторонами',],
'New Version': ['Новая версия',],
'Versions & Export': ['Версии и экспорт'],
'Create Subversion': ['Создать подверсию',],
'Current Version': ['Текущая версия',],
'Current<br><span>Version</span>': ['Текущая<br><span>версия</span>',],
'Based on a new document': ['Основано на новом документе',],
'Previous Versions': ['Предыдущие версии',],
'Document Navigation': ['Навигация по документу',],
'Back to previous paragraph': ['К предыдущему абзацу',],
'Compact': ['Компактная',],
'Full': ['Полная',],
'Created a new document': ['Создан новый документ',],
'Go to Version': ['Перейти к версии',],
'Show All': ['Показать всё',],
'Show Everyone':['Показать всех',],
'Changes Only': ['Только изменения',],
'Changes and Comments': ['Изменения и<br>комментарии',],
'Nothing yet.': ['Пока ничего нет',],
'Import New Version': ['Импортировать новую версию',],
'Exporting': ['Экспорт',],
'Remove':['Удалить'],
'Remove from Team?': ['Удалить из команды?',],
'Create Account': ['Создать аккаунт',],
'We sent you an email. Please confirm your account to start.': ['Мы отправили вам письмо. Пожалуйста, подтвердите ваш аккаунт, чтобы начать.',],
'Refresh the page': ['Обновить страницу',],
'Counterparty': ['Контрагент',],
'Imported': ['Импортирован',],
'The':[' ',],
'Copy':['Копировать',],
'Expand / Edit':['Развернуть / Редактировать',],
'Published': ['Опубликовано',],
'Published ': ['Опубликованные',],
'Variables': ['Переменные',],
'All': ['Все',],
'Text': ['Текст',],
'Number': ['Число',],
'Dropdown': ['Опции',],
'Fill value': ['Заполните значение'],
'Condition': ['Условие',],
'Created for Project': ['Создано для проекта',],
'Global': ['Глобальный',],
'Used in Project': ['Используется в проекте',],
'Insert': ['Вставить',],
'Enter Hint': ['Введите подсказку',],
'Enter Option Title': ['Введите название опции'],
'Enter Option Tip': ['Введите подсказку для опции',],
'Select Question': ['Выберите вопрос'],
'<Formula>':['<Формула>'],
'Fill Formula': ['Заполните формулу'],
'Select Option': ['Выберите опцию'],
'Required': ['Обязательно',],
'Default': ['По умолчанию',],
'Make Input': ['Сделать полем'],
'Add Option': ['Добавить опцию',],
'Add Condition': ['Добавить условие',],
'Advanced': ['Расширенные',],
'Add Default Value': ['Добавить значение по умолчанию',],
'Default Value': ['Значение по умолчанию',],
'Variable Name': ['Название переменной',],
'Variable Type': ['Тип переменной',],
'Add to Group': ['Добавить в группу',],
'Clone': ['Клонировать',],
'Move Up': ['Поднять вверх',],
'Move Down': ['Опустить вниз',],
'Enter Question': ['Введите вопрос',],
'Create a new template based on this version of this template': ['Создать новый шаблон на основе этой версии этого шаблона',],
'Publish': ['Опубликовать',],
'Create\xA0Document':['Создать\xA0Документ',],
'Settings': ['Настройки',],
'Add Template Description': ['Добавить описание шаблона',],
'Standart Team': ['Стандартная команда',],
'Same as in Master Document': ['Так же, как в основном документе',],
'Related Documents': ['Связанные документы',],
'Add Related Documents': ['Добавить связанные документы',],
'Publish Template?': ['Опубликовать шаблон?',],
"Don't Publish": ['Не публиковать',],
'Template Copied': ['Шаблон скопирован',],
'Got it': ['Понятно',],
'Time Machine': ['Машина времени',],
'The document is now edited by the Counterparty.': ['Теперь Контрагент редактирует документ',],
'Download pdf': ['Скачать pdf',],
'New': ['Новый',],
'Copy as New Document': ['Копировать как новый документ',],
'Request Cancelling Final Version': ['Запросить отмену финальной версии',],
'Go to DocuSign': ['Перейти к DocuSign',],
'Signed': ['Подписано',],
'Initiate DocuSign': ['Инициировать DocuSign',],
'Initiate signing the document with DocuSign': ['Начать подписание через DocuSign',],
'Upgrade Now!': ['Обновите сейчас!',],
'Send to DocuSign': ['Отправить в DocuSign',],
'Documents': ['Документы',],
'Documents to upload': ['Загружаемые документы',],
'Share': ['Поделиться',],
'Send to Counterparty': ['Отправить контрагенту',],
'<br><br>Please note your team already sent a request on ': ['Обратите внимание: ваша команда уже направляла запрос ',],
'Decline Request': ['Отменить запрос',],
'Standard Team': ['Стандартная команда',],
'Add Member': ['Добавить участника',],
'Add Member to Team': ['Добавить участника в команду',],
'Unpublish': ['Отменить публикацию',],
'Rename Template': ['Переименовать шаблон',],
'Delete Template': ['Удалить шаблон',],
'>Reply': ['>Ответить',],
'Reply': ['Ответить',],
'Comment': ['Написать',],
'Progress': ['Прогресс',],
'Collapse': ['Свернуть',],
'Version': ['Версия',],
'Subversion': ['Подверсия',],
'The other team requested to finalise this document': ['Другая команда отправила запрос на финализацию',],
'This message will be sent with this version':  ['Это сообщение будет отправлено вместе с версией',],
'This message is about to be sent':  ['Это сообщение сейчас отправится',],
'Are You sure You want to delete the entire thread?': ['Вы уверены, что хотите удалить все обсуждение?',],
'Are You sure You want to delete?': ['Вы уверены, что хотите удалить?',],
'This message was deleted by the author': ['Это сообщение было удалено автором',],
'Clause Library':['Формулировки'],
'New Clause':['Новая формулировка'],
'Drafts':['Черновики'],
'Categories and Clauses':['Категории и формулировки'],
'Modified on':['Дата изменения'],
'Modified by':['Кем изменено'],
'Uncategorized':['Без категории'],
'Clause Name':['Название формулировки'],
'Enter Clause Name':['Ввести название формулировки'],
'Category Name':['Название категории'],
'Enter Category Name':['Ввести название категории'],
'Add Tip':['Добавить подсказку'],
'Tip':['Подсказка'],
'Clause Text':['Текст формулировки'],
'Enter Clause Text':['Ввести текст формулировки'],
'Save Draft':['Сохранить черновик'],
'Cancel & Delete':['Отменить и удалить'],
'Select Category':['Выбрать категорию'],
'Enter Name':['Введите название'],
' and Clause Text':[' и текст формулировки'],
'Undo changes':['Отменить изменение'],
'Redo changes':['Восстановить изменение'],
'Now: This Version Changes':['Изменения в этой версии'],
'Showing 2 Latest Versions':['Изменения 2 последних версий'],
'Showing Clean':['Без правок'],
'Showing New':['Новые'],
'Show Newer':['Показать последние'],
'Thread Deleted':['комментарий удален'],
'Recent':['Последние'],
'General Info':['Основная информация'],
'Project Name':['Название проекта'],
'Project name':['Название проекта'],
'Contents':['Содержание'],
'Document Text':['Текст документа'],
'Go to Project':['Перейти в проект'],
'See here' :['Смотреть здесь'],
'Hide comment':['Скрыть комментарий'],
'Jump to Item':['Смотреть в документе'],
'Made Changes to the paragraph':['Внесены изменения в раздел'],
'Do you want to grant the right to edit?': ['Хотите предоставить права на редактирование'],
'Do you want to create a new version? ': ['Хотите создать новую версию? '],
' within your team.': [' в своей команде.'],
'Note that you\'re not able to change the previous versions.':['Обратите внимание, что вы не сможете изменить предыдущие версии.'],
'The External Team': ['Контрагент запросил'],
'requested editing': ['редактирование'],
'Еnter date': ['Введите дату'],
'There are no projects yet': ['Ещё не создано ни одного проекта'],
'Sending invites...': ['Отправка приглашений…'],
'Terminate Collaboration': ['Завершить совместную работу'],
'Review First': ['Перейти в документ'],
'Finalise': ['Финализировать'],
'Refuse': ['Отклонить'],
'Refuse to Finalise': ['Отклонить<brфинализацию'],
'Refuse to Finalise the Document': ['Отклонить финализацию документа'],
'Due at': ['Дедлайн'],
'The Document <br> is finalised by <br> everyone!': ['Документ финализирован'],
'Now it really is final!': ['Теперь это финальная версия!'],
'Perfect': ['Отлично'],
'Final Version': ['Финальная версия'],
'Request to Cancel Final Version': ['Запрос на отмену финальной версии'],
'Refuse to Cancel Final Version': ['Отклонить отмену финальной версии'],
'Another team sent a request to cancel Final Version.': ['Другая команда отправила запрос на отмену финальной версии.'],
'Confirm anyway': ['Всё равно подтвердить'],
'New Document Version': ['Новая версия документа'],
'Shared with the counterparty': ['Доступен контрагенту'],
'Shared with Counterparty': ['Доступен контрагенту'],
'Revoke Access': ['Отозвать доступ'],
'Sharing revoked': ['Доступ контрагента отозван'],
'Jump to Paragraph':['Перейти к абзацу'],
'Import as New Version': ['Импортировать как новую версию'],
'Unfreeze without Import': ['Разблокировать без импорта'],
'Continue with This Version': ['Продолжить с этой версией'],
'Return to Previous Version': ['Вернуться к предыдущей версии'],
'Time': ['Затраченное время'],
'Create': ['Создать'],
'Unpublish Template?': ['Отменить публикацию шаблона?'],
'Not all the required fields were filled out': ['Не все обязательные поля были заполнены'],
'Fill out': ['Заполнить поля'],
'Create anyway':['Создать в любом случае'],
'Not registered yet': ['Ещё не зарегистрирован'],
'Edit Name': ['Редактировать имя'],
'Add Name': ['Добавить имя'],
'First Name': ['Имя'],
'Last Name': ['Фамилия'],
'Forgot password?': ['Забыли пароль?'],
'Sign In': ['Войти'],
'Sign in': ['Войти'],
"Don't have an account?": ['Не зарегистрированы?'],
'Sign up': ['Регистрация'],
'Recover your password': ['Восстановить пароль'],
'Email address': ['Введите email'],
'Send instructions': ['Направить\xA0инструкции'],
'Return to the login screen': ['Вернуться на страницу входа'],
'Share with Note': ['Поделиться с заметкой'],
'The counterparty will be able to see this document. You can revoke access.': ['Контрагент сможет увидеть этот документ. Вы можете отозвать доступ.'],
'Share this document with the counterparty and send a note to it.': ['Поделитесь этим документом с контрагентом и отправьте ему заметку.'],
'Revoke': ['Отозвать'],
'Revoking Access': ['Отозвать доступ'],
'Sharing Document': ['Поделиться документом'],
'Are you sure you want to revoke access?': ['Вы уверены, что хотите отозвать доступ?'],
'Are you sure you want to share?': ['Вы уверены, что хотите отправить?'],
'Who is going to have access': ['Кто получит документ'],
'Already have an account?': ['Уже есть аккаунт?'],
'Email Address': ['Email'],
'Thank you for registration': ['Спасибо за регистрацию'],
'Enter new password': ['Введите новый пароль'],
'Re-enter your new password': ['Повторите ввод пароля'],
'You successfully changed your password': ['Пароль успешно изменен'],
"Please insert the company administrator's email to request joining the company.":['Пожалуйста, укажите адрес электронной почты админа компании, чтобы подать заявку на присоединение к компании.'],
'It seems there is no company administrator with such email.':['Неверный адрес админа компании. Проверьте, пожалуйста.'],
'The request has been sent.': ['Запрос был отправлен.'],
'Sign up and Sign in': ['Зарегистрироваться и войти'],
'invited you': ['приглашает вас'],
'You invited': ['Вы приглашены'],
'to collaborate': ['в проект'],
'To start, please sign up.': ['Для начала, пожалуйста, зарегистрируйтесь.'],
'to': ['в'],
'Save hours of work and hundreds of dollars every month.': [''],
' and ': [' и '],
' have not approved the document. Are you sure you want to send to Counterparty anyway?':
  [' не согласовали этот документ. Вы уверены, что хотите послать его без согласования?'],
'Current folder': ['Текущая папка'],
'Rename or Move': ['Изменить имя или папку'],
'Select folder': ['Выбрать папку'],



}

const longDict = {
    'requiredName': ['Name is required.','Требуется имя'],
    'requiredFirstName': ['First Name is required.','Требуется имя'],
    'requiredLastName': ['Last Name is required.','Требуется фамилия'],
    'minLengthName': ['The name seems too short.','Имя слишком коротко'],
    'maxLengthName': ['The name seems too long.','Имя слишком длинное'],
    'requiredSurname': ['Last name is required.','Требуется фамилия'],
    'minLengthSurname': ['The last name seems too short.','Фамилия слишком короткая'],
    'maxLengthSurname': ['The last name seems too long.','Фамилия слишком длинная'],
    'errorEmail': ['Email is not correct.',  'Некорректный адрес email'],
    'maxLengthEmail': ['The email is very long','Слишком длинный адрес'],
    'requiredEmail': ['Email is required.','Требуется ввести email.'],
    'corporateEmail': ['Only corporate email please','Разрешена только корпоративная почта'],
    'requiredPassword': ['Password is required.','Требуется пароль'],
    'minLengthPassword': ['Password must not be less than 8.','Длина пароля не должна бытьменьше 8'],
    'maxLengthPassword': ['The password is very long.','Слишком длинный пароль'],
    'documentNameMaxLength': ['Document name is a bit too long.  Please keep it under 256 characters.','Слишком длинное имя документа. Максимальная длина 256 символов.'],
    'projectNameMaxLength': ['Project name is a bit too long.  Please keep it under 256 characters.','Слишком длинное имя проекта. Максимальная длина 256 символов.'],
    'isCheckedEmail': ['The user is already in the project.','Пользователь уже есть в проекте'],
    'isFreePlanEmail': ['The user with FREE plan is not allowed to be in the internal group','Пользователь с тарифным планом FREE не может быть во внутренней группе'],
    'selfAddedEmail': ["You're already in the project",'Вы уже в проекте'],
    'alreadyTakenEmail': ['This email is already in use','Этот адрес уже используется'],
    'errorSendingEmail': ['An error occured while sending confirmation email','При посылке подтверждения произошла ошибка'],
    'incorrectPassword': ['Incorrect password.','Некорректный пароль'],
    'passMatch': ['Passwords must be equal.','Пароли должны совпадать'],
    'invitationExistsError': ['This user is already invited to this project','Пользователь уже приглашен в проект'],
    'incorrectLink': ['The link is not active any more. Request another link!','Ссылка больше не активна. Запросите другую.'],
    'revokeText': [
      'The couterparty would no longer have access to this file.',
      'Другая сторона потеряет доступ к этому файлу.'],
    'shareText': [
      'You are about to share this document together with external comments to the other party.',
      'Вы собираетесь поделиться этим документом вместе с внешними комментариями с другой стороной.' ],
    'Not all': [
      'Do you want to finish filling out or create the document with the incomplete fields anyway?', 
      'Вы все равно хотите завершить заполнение или создать документ с незаполненными полями?'],
    'Create Document': [
      `After you create a document from this template:<br/>
      <ul>
        <li class="marginb10">this document will become visible to the internal team</li>
        <li class="marginb10">everybody will be able to make edits to the text</li>
        <li class="marginb10">you will no longer be able to see fields, questions and options from the template—it will just become text</li>
      </ul>
      <div class='h3' style="margin-top: 40px;margin-bottom: 20px">
      Do you want to create a document?</div>`,
      `После создания документа по этому шаблону:<br/>
      <ul>
        <li class="marginb10">этот документ станет доступен внутренней команде</li>
        <li class="marginb10">каждый сможет вносить правки в текст</li>
        <li class="marginb10">вы больше не сможете видеть поля, вопросы и параметры из шаблона — они станут просто текстом</li>
      </ul>
      <div class='h3' style="margin-top: 40px;margin-bottom: 20px">
      Хотите создать документ?</div>`,
    ],
    'Unpublish Template?': [
        `This will hide this template from other users. <br><br>
                   Documents already created from this template 
                   will still be available to users.`,
        `Это скроет шаблон от других пользователей.<br><br>
        Документы, уже созданные на основе этого шаблона, по-прежнему будут доступны пользователям.`,
    ],
    'ifConfirm': [
        `If you confirm, a new version will be created.  The other team will be able to edit it.`,
        `Если вы подтвердите, будет создана новая версия. Другая команда сможет её редактировать.`
    ],
    'CancelFin': [
        `Are you sure you want to request cancelling Final Version?
        <br><br>
        If the other team confirms, a new version will be created.  Your team will be able to edit it.`,
        `Вы уверены, что хотите запросить отмену финальной версии?
        <br><br>
        Если другая команда подтвердит, будет создана новая версия. Ваша команда сможет её редактировать.`
    ],
    'cowReq': [
        'The Counterparty is requesting the right to edit the document. ', 
        'Контрагент запрашивает возможность редактировать документа. '],
    'cowApprove': ['Your team have to approve the document before send.', 
        'Ваша команда должна согласовать документ перед отправкой.'],
    'cowWhen': [
        'When you transfer the right to edit your changes become visible to the counterparty and you can continue editing after they return their changes.', 
        'После отправки право на редактирование перейдёт к контрагенту и ваши праки станут видны для него. Вы сможете продолжать редактирование после возврата документа контрагентом.'],
    'refuseStatus': [
        `The External Team refused <br/> to finalise the document`,
        `Внешняя команда отклонила финализацию документа`
    ],
    'refuseFin': [
        `Another team sent a request to finalise the document.
        <br><br>
        If you refuse, your team will be able to edit it.`,
        `Другая команда направила запрос на финализацию документа.
        <br><br>
        Если вы отклоните, ваша команда сможет его отредактировать.`,
    ],
    'reqFinHdr': [`The other party requested <br> to finalise Document`,
      `Другая сторона направила запрос на финализацию документа`
    ],
    'reqFinTxt': [`If you accept the request the document will become final.  The final document can no longer be amended. <br><br>
        If the parties will need to change the final document later, they can undo finalisation or create a new document from this one.`,
        `Если вы примете запрос, документ станет финальным. В финальный документ уже нельзя вносить изменения.<br><br>
        Если сторонам позже потребуется изменить финальный документ, они могут отменить финализацию или создать новый документ на базе этого.`,
    ],
    'ownChgs': [
        'You cannot accept your own changes. This might be confusing to other readers.',
        'Вы не можете принять свои собственные правки. Это может сбить с толку других пользователей.',
    ],
    'mute': [
        `You can mark changes as mutes, for example if they don't need your attention any longer. <br> 
        <br>
        They will be shown in grey only to you.  If their status changes (e.g. somebody accepts new changes) you will see that.`,
        `Вы можете пометить изменения как неактивные, например, если они больше не требуют вашего внимания.<br> 
        <br>Они будут показаны серым только вам. Если их статус изменится (например, кто-то принимает новые изменения), вы это увидите.`,
    ],
    'Start with your doc': [
        `Start with your document.<br />Invite colleagues and counterparties.<br />Make it`,
        `Начните с вашего документа.<br />Пригласите коллег и контрагентов.<br />Создайте свой проект`,        
    ],
    'add demo': [
        `Add a <span class="color_lightRed"> demo </span> project`,
        `Создать<span class="color_lightRed"> демо </span>проект`,
    ],
    'see demo': [
        'See what an active project may look like with our test document',
        'Посмотрите, как может выглядеть активный проект, с помощью нашего тестового документа.'
    ],
    'Create an automated NDA': [
        `<span>Create an <span class="color_orange"> automated NDA</span></span>`,
        `<span>Создать <span class="color_orange"> NDA</span></span>`,        
    ],
    'Set up a simple NDA': [
        `Set up a simple NDA.<br />Add counterpary.<br />Agree in no time`,        
        `Настроить Соглашение о конфиденциальности.<br />Добавить контрагента.<br />Быстро согласовать`,
    ],
    'Create a company account': [
        `Create a company account. Manage teams, members, access rights and keep company projects together.`,
        `Создайте учетную запись компании. Управляйте командами, участниками, правами доступа и держите все проекты компании в одном месте.`,        
    ],
    'This template': [
        `This template is provided for informational and educational purposes only. We recommend you consult with your attorney before using it.`,
        `Мы добавили этот шаблон для информационных и образовательных целей.  Прежде чем использовать его, рекомендуем проконсультироваться с вашим юристом.`,        
    ],
    'This template1': [
        `Contract.one isn’t a law firm or a substitute for an attorney or law firm. We don’t provide legal advice.`,
        `Contract.one — не юрфирма и не даёт юридических консультаций`,        
    ],
    'team will be able': [
        `This team will be able to see <br/>
        <span>and edit</span> the document.`,
        `Контрагент сможет просматривать <br/>
        <span>и редактировать</span> документ.`,        
    ],
    'fstDraft': [
        'This is the first draft. Are the tracked changes intended just for your team? Or—',
        'Это первый черновик. Предназначены ли правки только для вашей команды? Или —',        
    ],
    'fstDraft1': [
        'Do you want to show them to the Counterparty?',
        'Вы хотите показать их контрагенту?',        
    ],
    'over': [
        `The other Team is editing the document. You are about to take the control over editing. 
        <br>This would create a new version based on the latest version available to you. But this will not include changes the Counterparty is working on right now. 
        <br>It's important not to abuse overtake.  Please use it when it's absolutely necessary.`,
        `Другая команда редактирует документ. Вы собираетесь взять на себя контроль над редактированием.
        <br>Это создаст новую версию на основе последней доступной <i>вам</i> версии. Но новая версия не будет включать правки, над которыми сейчас работает Внешняя команда.
        <br>Важно этим не злоупотреблять. Пожалуйста, используйте этот функционал только в экстренных ситуациях.`,        
    ],
    'final': [
        `<div >When you are ready with the final version, you can finalise from your side.  The other party will get a request for finalising. <br>
        <br>
        The document becomes final when the other party accepts the request.  The final document can no longer be amended. <br>
        <br>
        If the parties will need to change the final document later, they can undo finalisation or create a new document from this one.</div>`,        
        `<div >Когда вы будете готовы с окончательной версией, вы можете финализировать её с вашей стороны. Другая сторона получит запрос на финализацию.<br>
        <br>
        Документ становится финальным, когда другая сторона примет запрос. Финальный документ больше не получится править.<br>
        <br>
        Если сторонам всё ещё нужно изменить окончательный документ, они могут отменить финализацию или создать новый документ на основе этого.</div>`,
    ],
    'together': [
        `You and the other team will collaborate together in real time. <br/>
        The other party will immediately see: <br/>
        &#8194; • all the new changes; <br/>
        &#8194; • all the external communication.`,
        `Вы и другая команда будете редактировать одновременно в режиме реального времени.<br/>
        Другая сторона немедленно увидит:<br/>
        &#8194; • все новые правки; <br/>
        &#8194; • всю внешнюю коммуникацию.`,
    ],
    'We sent': [
        `We sent the request to finalise the document to the other party.<br>
        <br>
        Now you need to wait until they finalise.`,
        `Мы отправили запрос на финализуацию документа другой стороне.<br>
        <br>
        Теперь вам нужно дождаться, пока они его финализируют.`,        
    ],
    'cancel fin': [
        `Are you sure you want to cancel finalising the document?
        <br><br>
        If you do, you will be able to edit the document.`,
        `Вы уверены, что хотите отменить финализацию документа?
        <br><br>
        Если да, то вы сможете редактировать документ.`,        
    ],
    '5less': [
        `In your Free Account you can only create a new project or add a document if you have 5 or less documents. `,
        `В вашем бесплатном аккаунте вы можете создавать новый проект или добавлять документ, только если у вас 5 или меньше документов. `,        
    ],    
    'templateAvailable': [
        `Make this template available to <br />
        other users. You can publish any version of this template.`,
        `Сделайте этот шаблон доступным для 
        других пользователей. Вы можете опубликовать <br />
        любую  версию этого шаблона.`,        
    ],
    'templateHide': [
        `Hide this template from other users. <br /><br />
        Documents already created from this template will still be available to users.`,
        `<br /><br />Скройте этот шаблон от других пользователей.<br /><br />
        Документы, уже созданные на основе этого шаблона, по-прежнему будут доступны пользователям.`,        
    ],
    'to finalise': [
        `<span>The other team requested <br/> to finalise this document</span>`,
        `<span>Другая команда отправила <br/> запрос на финализацию</span>`,        
    ],
    'immediateComment': [
        `This particular comment will be sent immediately. <br> It can be useful when you need to share or request information with the other team without waiting for the entire document to be ready for being sent.`,
        `Этот конкретный комментарий будет отправлен прямо сейчас. <br>Это может быть полезно, когда вам нужно быстро поделиться информацией с другой командой, не дожидаясь, пока эта версия будет готова`,        
    ],    
    'intComment': [
        `This is an internal comment for your team.</div>
        It is encrypted and stored separately. Other parties do not have access to it.`,        
        `Это внутренний комментарий для вашей команды.</div>
        Он зашифрован и хранится отдельно. Другие стороны не имеют к нему доступа.`,
    ],   
    'privComment': [
        `This is a private comment for just you.</div>
        It is encrypted and stored separately. Other parties do not have access to it.`,        
        `Это личный комментарий, доступный только вам.</div>
        Он зашифрован и хранится отдельно. Другие стороны не имеют к нему доступа.`,
    ],  
    'delMessages': [
        `Please delete the unsent messages from the thread and resolve, or resolve later, when there are no unsent messages left.`,
        `Пожалуйста, удалите неотправленные комментарии из обсуждения и затем завершите обсуждение или завершите позже, когда не останется неотправленных комментариев.`,        
    ],
    'New to C1?': [
      `New to ${allocName}?`,
      `Впервые в ${allocName}?`
    ],
    'ifRefuse': [
        `If you refuse, the version will stay final and the document will be locked to changes.`,
        `Если вы отклоните, версия останется финальной и документ будет заблокирован для редактирования.`
    ],
    'Still': [
        `Confirmation of new email is pending.<br/>
         For now we are still using `,
        `Ожидается подтверждение нового адреса электронной почты.<br/>
        На данный момент мы по-прежнему используем `],

    
    'pks42': [
        ``,
        ``,        
    ],
}

const funcDict = {
    'reqFin': [
        (other) => `${other? `The other` : `Your`} team requested <br/> to cancel Final Version`,
        (other) => `${other? `Другая` : `Ваша`} команда запросила <br/>отменить финальную версию`,
    ],
    'ifConfirm': [
        (approverUserList, approved) => `<div class='h3' style="margin-top: 50px;margin-bottom: 20px">Approvals Needed 
        (${(approverUserList && approved) ? ` ${approverUserList.length - approved.length}/${approverUserList.length}` : ''})</div> 
        Before you can cancel the final version you need the document to be approved by:`,
        (approverUserList, approved) => `<div class='h3' style="margin-top: 50px;margin-bottom: 20px">Требуются согласования 
        (${(approverUserList && approved) ? ` ${approverUserList.length - approved.length}/${approverUserList.length}` : ''})</div> 
        Прежде чем вы сможете отменить финальную версию, вам необходимо, чтобы документ был согласован:`,
      ],
    'sent a request': [
        requestTo => 
            `You sent a request to ${requestTo} to join a company. You can cancel it.`,
        requestTo => 
            `Вы направили запрос ${requestTo} на присоединение к компании. Вы можете отозвать свой запрос.`,
    ],    
    'You can add': [
        docsLeft => `You can add ${docsLeft} document for free.`,
        docsLeft => `Вы можете добавить ${docsLeft} документов бесплатно.`,
    ],
    'You already have': [
        docsCount => `You already have ${docsCount} documents in your account.`,
        docsCount => `На вашем аккаунте уже есть документы (${docsCount}).`,
    ],
    'Nothing new': [
        s => `Nothing new <span>since <br/>${s}</span>`,
        s => `Ничего нового <span>с<br/>${s}</span>`,
    ],
    'changesCount': [
        changesCount => `${changesCount} change${changesCount > 1 ? 's were' : ' was'} neither accepted or declined.`,
        changesCount => `Правки (${changesCount}) не были ни приняты, ни отклонены.`,
    ],
    'After you publish': [
        displayVersion => `After you publish this template, users will be able to see it in 
        Templates List and create a new document from it. <br><br>
        You can always unpublish template. <br><br>
        You can publish any version of this template.
         ${displayVersion ? 
         `<div>
             <div style="margin: 40px 0 20px; font-weight: bold">Not Latest Version</div>
             <div>
               You are about to publish 
               <span style="color: var(--color_lightRed)">Version ${displayVersion}</span> of 
               this template.   It is not the latest version.
             </div>
         </div>`
         : ''}`,
        displayVersion => `После публикации этого шаблона пользователи смогут увидеть 
        его в списке шаблонов и создать новый документ на его основе.<br><br>
        Вы всегда можете отменить публикацию шаблона.  <br><br>
        Вы можете опубликовать любую версию этого шаблона.
         ${displayVersion ? 
         `<div>
             <div style="margin: 40px 0 20px; font-weight: bold">Не последняя версия</div>
             <div>
               Вы собираетесь опубликовать 
               <span style="color: var(--color_lightRed)">версию ${displayVersion}</span> of 
               этого шаблона, которая не является последней.
             </div>
         </div>`
         : ''}`,
    ],
    'templateCopied': [
        (oldName, template) => `A copy of the template ${oldName} was created ${template ? '' : 'but smth went wrong. Please reload this page.'}`,
        (oldName, template) => `Копия шаблона ${oldName} создана ${template ? '' : ', но что-то пошло не так. Пожалуйста, перезагрузите страницу.'}`,
    ],
    'MadeRights': [
        (rights, firstname, lastname, dateString) =>  `Made ${rights} by ${firstname} ${lastname} on ${dateString}`,
        (rights, firstname, lastname, dateString) =>  `${firstname} ${lastname} предоставил(а) права ${
            rights === 'Admin' ? 'Админа' : 'Согласующего'} ${dateString}`,
    ],
    'invidedBy': [
        (firstname, lastname, dateString) => 
            `Invited by ${firstname} ${lastname} on ${dateString}`,
        (firstname, lastname, dateString) => 
            `${firstname} ${lastname} пригласил(а) ${dateString}`,
    ],
    'Joined': [
        s => `Joined the Project on ${s}`,
        s => `Присоединился(ась) к проекту ${s}`,
    ],
    'approvedBy': [
        (a,b) => `Approved by ${a} out of ${b}`,
        (a,b) => `Согласовали ${a} из ${b}`,
    ],
    'days': [
        s => `${s} day${s > 1 ? 's' : ''}`,
        s => `${s} ${(s+9)%10 > 3 ? 'дней' : (s+9)%10 ? 'дня' : 'день'}`,
    ],
    'changes': [
        len => `${len} change${len > 1 ? 's' : ''}`,
        s => `${s} изменени${(s+9)%10 > 3 ? 'й' : (s+9)%10 ? 'я' : 'е'}`,
    ],
    'SentOpen': [
        s => s,
        s => momentRus(s.replace('Sent','Отправлено').replace('Not opened yet','Ещё не открыто')
          .replace('Opened','Открыто')),
    ],
    'resolvedOn': [
        (a,b) => `Resolved by ${a} on ${b}`,
        (a,b) => `Завершено ${a} ${b}`,
    ],
    'statuses': [
        (un, op, person) => `${(un ? 'Un-' : '')}${
            ['none','Inserted','Deleted','Accepted','Accepted','Declined',
            'Declined','Un-Accepted','Un-Declined'][op]} by ${person}`,
        (un, op, person) =>  (un ? 
          ['none','Отменена вставка','Отменено удаление',
            'Отменено принятие','Отменено принятие',
            'Отменено отклонение', 'Отменено отклонение',
            '!Отменено принятие','!Отменено отклонение'][op]
          : ['none','Вставлено','Удалено','Принято','Принято','Отклонено',
            'Отклонено','Отменено принятие','Отменено отклонение'][op]) 
          + ' ' + (person === 'You' ? 'Вами' : person),
    ],
    'pinNav': [
        isPin => `${isPin ? 'Unpin' : 'Pin'} Navigation`,
        isPin => `${isPin ? 'Открепить' : 'Закрепить'} навигацию`,
    ],
    'delTemplate': [
      isFolder => `Do you want to remove the ${isFolder ? 'folder' : 'template'}?`,
      isFolder => `Вы действительно хотите удалить ${isFolder ? 'папку' : 'шаблон'}?`,
    ],
    '_origination': [
        (no, shift, first) => {
            const based = "Based on the version ";
      switch(no + shift) {
          case 1: return (first ? 'Based on' : 'Created') + ' a new document';
          case 2:
          case 23: return (first ? based + 'sent' : "Sent") + " to the External Team";
          case 3:
          case 22: return (first ? based + 'received' : "Received") +  " from the External Team";
          case 4:
          case 25: return (first ? based + 'overtaken' : "Overtaken") + " by the Internal Team";
          case 5:
          case 24: return (first ? based + 'overtaken' : "Overtaken") + " by the External Team";
          case 6:
          case 27: return (first ? based + 'sent to collaborate' : "Sent to collaborate to the External Team") + "";
          case 7:
          case 26: return (first ? based + 'received to collaborate' : "Received to collaborate from the External Team") +  "";
          case 28:
          case 8:  return (first ? based + 'after collaboration' : "Received after collaboration") +  "";
          case 20:  return (first ? based + 'imported' : "Imported version") +  "";
          case 21: return (first ? based + 'frozen' : "Frozen version") +  "";
          case 29:
          case 9:  return (first ? based + 'sent' : "Sent") +  " to finalize";
          case 30:
          case 10:  return (first ? based + 'received' : "Received") +  " to finalize";
          case 31:
          case 11:  return (first ? based + 'refused' : "Refused") +  " to finalize";
          case 32:
          case 12:  return (first ? based + 'final' : "Final") +  " version";
          case 33:
          case 13:  return (first ? based + 'received' : "Received") +  " to finalize";
          case 34:
          case 14:  return (first ? based + 'received' : "Received") +  " to finalize";
          case 50:
          case 70:  return (first ? based + 'imported' : "Imported Version") +  " (preview)";
          default: return first ? based + 'saved' : "Saved version";
      }
        },
    (no, shift, first) => {
            const based = "Создана из ";
      switch(no + shift) {
          case 1: return first ? 'Создана из нового документа' : 'Создан новый документ'
          case 2:
          case 23: return (first ? based + 'отправленного' : "Отправлено") + "  внешней команде";
          case 3:
          case 22: return (first ? based + 'принятого' : "Принято") +  " от внешней команды";
          case 4:
          case 25: return (first ? based + 'перехваченного' : "Перехвачено") + " нашей командой";
          case 5:
          case 24: return (first ? based + 'перехваченного' : "Перехвачено") + " вненей командой";
          case 6:
          case 27: return (first ? 'Создано во время' : "Отправлено для") + " совместной работы";
          case 7:
          case 26: return (first ? based + 'принятого' : "Принято") +  " для совместной работы";
          case 28:
          case 8:  return (first ? based + 'принятого' : "Принято") +  " после совместной работы";
          case 20:  return (first ? based + 'импортированного документа' : "Импортированная версия") +  "";
          case 21: return (first ? based + 'заблокированного для импорта документа' : "Заблокированная для импорта версия") +  "";
          case 29:
          case 9:  return (first ? based + 'посланного' : "Послано") +  " для финализации";
          case 30:
          case 10:  return (first ? based + 'принятого' : "Принято") +  " для финализации";
          case 31:
          case 11:  return (first ? 'Возвращено после отказа' : "Отказано") +  " в финализации";
          case 32:
          case 12:  return (first ? based + 'финальной версии' : "Фмнальная версия") +  "";
          case 33:
          case 13:  return (first ? based + 'принятого' : "Принято") +  " для финализации";
          case 34:
          case 14:  return (first ? based + 'принятого' : "Принято") +  " для финализации";
          case 50:
          case 70:  return (first ? based + 'импортированного документа' : "Импортированная версия") +  " (превью)";
          default: return first ? based + 'сохраненной версии' : "Сохраненная версия";
      }
        },

    ],
    'toThread': [
        (upd, isThread) => (upd ? 'Updated' : 'Added') + ' a ' 
                + (isThread ? '' : 'comment to the ') + 'thread',
        (upd, isThread) => (upd ? 'Изменен' : 'Добавлен')  
                + (isThread ? 'о обсуждение' : ' комментарий'),    
    ],
    'showMore': [
        s => `Show ${s} more items`,
        s => `Показать ещё ${s}`,
    ],
    'ShowSearch': [
        (s, score) => `Showing ${s} results of search`,
        (s, score) => `Результат поиска: ${s}`,
    ], //${      score ? "score " + score : ""    }
    'Smth del': [
      (isComment, isExternal) =>
        `<span>${isComment ? 'Comment' : 
          ((isExternal ? 'External' : 'Internal') + 'Thread')}</span> Deleted`,
      (isComment, isExternal) =>
        `<span>${isComment ? 'Комментарий' : 
          ((isExternal ? 'Внешнее ' : 'Внутреннее ') + 'обсуждение')}</span> удален${
            isComment ? '' : 'о'}`,
    ],
    'Due': [x =>x,
      x => momentRus(x.replace('Due', 'Дедлайн').replace('Overdue', 'Просрочен'))
    ],
    'statIntExt': [
        (comments, int, ext) => `<div>${int + ext} ${comments ?
            'Comments' : 'Edits'}
            <div class="paraStats-desc"> (${
            int} internal + <br> ${
            ext} external) </div></div>`,
        (comments, int, ext) => `<div>${comments ?
            'Комментарии' : 'Правки:'}: ${int + ext}
                <div class="paraStats-desc"> (внутренние: ${
                 int}  + <br>внешние: ${ext}) </div></div>`,
    ],
    'times': [n => `${n} time${n === 1 ? '' : 's'}`,
      n => `${n} раз`
    ],
    
    'funv': [
        s => ``,
        s => ``,
    ],

}

const momentRus = s => s.replaceAll('a few', 'несколько').replaceAll('second','сек.')
  .replaceAll('minute','мин.').replaceAll('ago','назад')
  .replaceAll('hour','ч.')
  .replaceAll('Today','Сегодня')
  .replaceAll('day','дн.').replaceAll('month','мес.')
  .replaceAll('year','г.').replaceAll('s','').replaceAll('an ','1 ')
  .replaceAll('a ','1 ').replaceAll('in ',' ')

export const transLoc = key =>
  (!allocSimplawyer) ? key : simpleDict[key][0]

export const transS = key =>
    (pos === -1 || !simpleDict[key]) ? key : simpleDict[key][pos]

export const transL = key => longDict[key][pos+1]
export const transEL = key => longDict[key][!allocSimplawyer ? 0 : 1]

export const transF = key => funcDict[key][pos+1]

export const transR = (s, key) =>
    (pos === -1 || !simpleDict[key]) ? s : s.replace(key, simpleDict[key][pos])

export const parseDate = s => {
  if (!s) return null
  const regex = pos === -1 ? /(\d{2})\/(\d{2})\/(\d{4})/ : /(\d{2})\.(\d{2})\.(\d{4})/,
    result = s.match(regex)
  if (!result) return null
  const month = pos === -1 ? result[1] : result[2],
    day = pos === -1 ? result[2] : result[1]
  return new Date(result[3], month-1, day)
}

const pad0 = x => (x+'').padStart(2,'0')

export const showDate = d => !d ? '' :
    pos === -1 ? `${pad0(d.getMonth()+1)}/${pad0(d.getDate())}/${d.getFullYear()}`
      : `${pad0(d.getDate())}.${pad0(d.getMonth()+1)}.${d.getFullYear()}`

const shortMonth =  ['Jan','Feb','Mar','Apr', 'May', 'June', 'July', 'Aug', 
'Sep', 'Oct', 'Nov', 'Dec'],
  longMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  rusMonth = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 
  'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

export const showMonth = d => !d ? '' : ((pos === -1) ?
  shortMonth[d.getMonth()]
  : rusMonth[d.getMonth()]) + ', '
    + d.getFullYear()

export const statDate = (time) => { 
const start = pos === - 1 ? `${longMonth[time.getMonth()]} ${time.getDate()}, ${
    time.getFullYear()}` :  showDate(time)
return `${start}, ${pad0(time.getHours())}:${pad0(time.getMinutes())}`
}

export const getDoWeek = () => pos === - 1 ?
  ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
  : ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

export const transLogo = white => 
  allocSimplawyer ? `/images/${white ? 'logo-simplawyer-one-white.png' : 'logo-simplawyer-one.png' }`
  : `/images/${white ? 'c1-logo_white.png' : 'c1-logo.png' }`
