import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import CSSModules from 'react-css-modules'
import {cloneDeep} from "lodash";
import styles from './templatesUnderMenu.css'
import TextArea from './TextArea';
import CheckBox from './CheckBox';
import DropDownType from './DropdownType';
import ScrollBar from "../../../../../../components/scrollBar/ScrollBar";
import useEvent from '../../../../../../services/helpers/use-event';
import { getPopUpAction } from '../../../../../../services/actions/dataActions';
import api from '../../../../../../services/api/api';
import {popUpReset} from "../../../../../../services/helpers";
import { setVarList, setVarClick, showDateControl } from '../../../../../../redux/document/actions';
import { transliterate } from '../../../../../../../common/transliterate';
import { getLanguage, transS } from '../../../../../../services/helpers/lang';
import Conditional from './Conditional';
import DateControl from '../../../dateControl/DateControl';

const Variables = params => {
  const [viewVars, setViewVars] = useState("project");
  const [lastViewed, setLastViewed] = useState(0);
  const [editedVar, setEditedVar] = useState(0);
  const [hiVar, setHiVar] = useState(0);
  const [posVar, setPosVar] = useState(0);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const setHeadRadio = x => setViewVars(x);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const _getDropDown = (el, arrow, func) => el === 'if' ? false :
    <DropDownType key={el} type={el} container="1" changed={func} arrow={arrow} />;

  const handleResize = (_) => {
    let top = containerRef.current.getBoundingClientRect().top;
    if (containerRef?.current?.style)
      containerRef.current.style.height = (window.innerHeight - top) + 'px';
  }; 
  useEvent('resize', handleResize);
  useEffect(() => handleResize(), []);
  const {varlist, actualDocument, viewedNew, usedVars, 
    click, clickPosition, dateControl} = useSelector(state => state.document);
  const {insertOn, frameProgress} = useSelector(state => state.paragraphReducer);
  const rOnly = actualDocument.orig !== 1;
  const isDraftDoc = actualDocument.orig === 302 
  const [showDefault, setShowDefault] = useState(false);
  const [showConditional, setShowConditional] = useState(false);
  useEffect(() => handleClickVar(), [click]);
  useEffect(() => handleClickPositionVar(), [clickPosition]);
  useEffect(() => {if (editedVar && rOnly && !isDraftDoc) setEditedVar(0);}, [rOnly]);
  let uncheckUsed = !usedVars || !('has' in usedVars) || !usedVars.has(1);
  const [condParts, setCondParts] = useState(new Set())

  const clearEdited = () => {
    setEditedVar(0); setShowAdvanced(false); 
    setShowDefault(false); setShowConditional(false);
    setHiVar(0), setPosVar(0)};
  const setEdited = id => {
     setShowAdvanced(false); 
     setShowDefault(false); setShowConditional(false);
     setEditedVar(id);
  }
  const clickOutsideHandler = ({target}) => {
     if (!containerRef.current || !target || !document.contains(target)) return; 
     if (!containerRef.current.contains(target)) return clearEdited();
     while (target && target !== containerRef.current) {
       if (target.dataset.edited == "true") return;
       target = target.parentElement;
     }
     clearEdited();
  }
  useEffect(() => {
        window.addEventListener('click', clickOutsideHandler)
        window.addEventListener('blur', clearEdited)
        return () => {
            window.removeEventListener('click', clickOutsideHandler)
            window.removeEventListener('blur', clearEdited)
        }
    }, []);

  useEffect( () => {
    if (!varlist) return;
    const condParts = new Set()
    let checkFull = 1
    if (isDraftDoc) {
      varlist.forEach(v => {
        if (!v.conditional || checkCondition(v.conditional)) {
            condParts.add(v.id)
        }})
      while (checkFull) {
        checkFull = 0
        varlist.forEach(v => {
          if (v.conditional && condParts.has(v.id))
            if (!condParts.has(v.conditional.condVar)) {
              condParts.delete(v.id)
              checkFull = 1
            }})
      } 
    } else {
      varlist.forEach(v => v.conditional && condParts.add(v.conditional.condVar))
    } 
    setCondParts(condParts)     
  },[varlist])
  
  const radioElement = (name, text) =>
    (<div key={name} 
       onClick={()=> {
         setHeadRadio(name);
         clearEdited(); }
       }
       data-current={name === viewVars} >
       {text}</div>);
  const chgVarList = (list, el) => {
     api.updateTemplate(actualDocument.coreDocument
       , 'c1 send vars'+(el ? " and doc" : ""), undefined, actualDocument._id, list);
     dispatch(setVarList(list));
     if (el && frameProgress) frameProgress.sendToFrame({c1:'variable', action: 'list', varlist: list});
     return null; 
  };
  const overVar = (id, on) => {
    dispatch(setVarClick(Date.now(), on ? id : 0, 0));
    if (frameProgress) frameProgress.sendToFrame({c1:'variable', action: 'click', id, on});
  }
  const cloneVar = item => {
    let newItem = cloneDeep(item), mark = Date.now(), id = mark,
      list = [...[newItem], ...varlist];
    newItem.name = checkUnique(newItem.title || newItem.name, newItem);
    newItem.id = mark++;
    if (newItem.dropdown)
      newItem.dropdown.forEach(opt => {opt.id = mark++;});
    chgVarList(list);
    clearEdited();
    setEdited(id);
  }
  const checkUnique = (arg, el) => {
   let checkName = arg ? transliterate(arg.trim().replace(/ /g,"_")).substr(0,40)
     : 'Variable', varnumber = 0;
   while (checkName) {
     let name = checkName + ( varnumber ? '_'+varnumber : '');
     if (varlist.find(e => e !== el && e.name === name)) varnumber++;
     else return name;
   }        
  }
  
  const handleClickVar = () => {
    if (!click) return;
    setHiVar(click.record);
    }
  const handleClickPositionVar = () => {
    if (!clickPosition) return;
    if (!clickPosition.record) setPosVar(0);
    else {
      let el = containerRef.current.querySelector(`[data-id="${clickPosition.record}"]`);
      if (!el) return;    
      let top = el.getBoundingClientRect().top; 
      containerRef.current.firstElementChild.firstElementChild.scrollBy(0, top - clickPosition.y - 114);  // need calc
      setPosVar(clickPosition.record)
    }
  }
     
  const varItem = el => {
    let {id, title, hint, required, type, defaultValue, name, realname, conditional, dropdown, value} = el;
    const insertButton = (option) => (<button styleName="variableField_insertBtn" 
        data-off={!insertOn || !title } onClick={(e) => { insertVar(option);
            e.stopPropagation()}}>{transS('Insert')}</button>);
    const changedVarList = (item, container) => {
       if (item === "name") {
           let newName = checkUnique(el.name, el);
           if (newName !== el.name) return newName;
           el.name = newName;
           el.realname = true;           
       }
       return chgVarList(varlist, container);
    }
    const removeVar = () => {
      dispatch(getPopUpAction({
        name: 'confirm',
        text: `Do you want to remove the variable <br><span style="word-break:break-all;">${el.name}</span>?`,
        confirm: {
          name: transS('Delete'),
          danger: true,
          event: () => {
            chgVarList(varlist.filter(e => e !== el), el);
            dispatch(getPopUpAction(popUpReset))
          }
        },
        cancel: {
          name: transS('Cancel'),
          event: () => dispatch(getPopUpAction(popUpReset))
        }
      }));
    }
    const moveUp = (isUp) => {
      let i = varlist.findIndex(e => e === el), j = i;
      if (isUp) {
        j--;
        if (viewVars === "used")
          while (j >= 0 && !varlist.used) j--;
        if (j === -1) return;
      } else {
        j++;
        if (viewVars === "used")
          while (j <varlist.length && !varlist.used) j++;
        if (j === varlist.length) return;
      }
      varlist.splice(j, 0, varlist.splice(i,1)[0]);
      changedVarList();
    };
    const insertVar = (option) => {
      if (!insertOn) return;
      frameProgress.sendToFrame({c1:'variable', action: 'insert', id, option});
    }

    if (!realname) {
       el.name = name = checkUnique(title, el);
    }    
    const dropSelect = dd => {
      if (value === dd.id) {
        el.value = null
      } else el.value = dd.id;
      clearEdited();
      changedVarList(varlist, el);
    }
    const dropdownItem = dd => ( isDraftDoc ?
      (dd.option || dd.tip || value===dd.id) &&
      <div styleName="variableField_dropdownPart_option" key={dd.id} data-option={dd.id} style={{display:"block"}}>
        <div styleName="questionDrop" data-selected={value === dd.id}
            onClick={() => dropSelect(dd)}>
          <CheckBox filled={value === dd.id}
            text=""  ronly={true} big="1"/> 
          {dd.option}</div>
        {dd.tip && <div styleName="hintDrop">{dd.tip}</div> }
      </div>
      :
      <div styleName="variableField_dropdownPart_option" key={dd.id}>
        <div styleName="variableField_dropdownPart_option_left">
         <TextArea name="option" placeholder={transS('Enter Option Title')} big="1"
           container={dd} ronly={rOnly} changed={changedVarList}
           styleCorrect={getLanguage() ? '' : ' correctWidth'} 
         />
         <TextArea name="tip" placeholder={transS('Enter Option Tip')}
           container={dd} ronly={rOnly} changed={changedVarList}
         />
         <div>{insertButton(dd.id)}</div>
        </div>
        <div styleName="variableField_dropdownPart_option_right">
          <CheckBox filled={defaultValue === dd.id}
            text={transS('Default')}  ronly={rOnly} 
            changed={() => { if (rOnly) return;
               el.defaultValue = (defaultValue === dd.id) ? null : dd.id; 
                changedVarList();}} />          
        </div>
        <span styleName="cross" onClick={() => !rOnly && deleteOption(dd.id)}><span></span>
        </span>
      </div>);
    const deleteOption = id => {
      if (!el.dropdown) return;
      let index = el.dropdown.findIndex(el => el.id === id);
      el.dropdown.splice(index,1);
      changedVarList('', el);
    };
    const addOption = () => {
       const newEl = {id:Date.now(), option:'',tip:''};
       if (!el.dropdown) el.dropdown = [newEl];
       else {
         el.dropdown.push(newEl);
       }
       changedVarList();
    }
    const dropdownPart = dropdown => (
       <div styleName="variableField_dropdownPart">
         <div styleName="variableField_dropdownPart_options">
           {dropdown && dropdown.map( el => dropdownItem(el))}   
         </div>
         {!rOnly && <button styleName="variableField_dropdownPart_btn" 
            onClick={addOption}>{transS('Add Option')}</button>}
       </div>);
    
    const getFromDropdown = index => {
      let x = el.dropdown.find(e => e.id === index);
      return x ? x.option : 'not defined';
    }
    const advancedPart = () => (
       <div styleName="variableField_advanced">
          <div styleName="variableField_advanced_title">{transS('Advanced')}</div>
          {type !== 'date' && <div>
            {defaultValue || showDefault ? <>
               <div styleName="variableField_advanced_paramName">{transS('Default Value')}</div>
               <TextArea name="defaultValue" big="2" 
                 defValue={type === "dropdown" ? getFromDropdown(defaultValue) : false}
                 container={el} changed={changedVarList} 
                 ronly={rOnly || type === "dropdown"}
                 check={type === "number" ? (a) => isNaN(a): false} />
            </> : !rOnly && <button onClick={() => setShowDefault(true)}>
              {transS('Add Default Value')}
            </button> }
           </div>}
           <div>
              {
                (conditional || showConditional) ? 
                <>
                  <div styleName="variableField_advanced_paramName">
                    {transS('Condition for This Variable')}</div>
                  <Conditional 
                    el = {el}
                    varlist = {varlist}
                    changedVarList = {changedVarList}
                    kill = {() => { if (el.conditional) {
                        delete el.conditional
                        changedVarList()
                      } 
                      setShowConditional(false)} }
                    readOnly = {rOnly}
                  />      
                </>            
                : !rOnly && <button onClick={() => {
                    setShowConditional(true)
                  }}>
                  {transS('Add Condition')}
                </button>
              }
           </div>
          <div styleName="lastAdvanced">
            <div>
              <div styleName="variableField_advanced_paramName">
                {transS('Variable Name')}</div>
              <TextArea name="name" big="2" defValue={name} 
                 container={el} ronly={rOnly} changed={changedVarList} />  
            </div>
            <div>
               <div styleName="variableField_advanced_paramName">
                 {transS('Variable Type')}
               </div>
               {!(defaultValue || dropdown) && <span styleName="borderel"></span>}
               <DropDownType type={type} container={el} 
                 changed={(defaultValue || dropdown || rOnly) ? null : changedVarList}
                 func={_getDropDown} plus="1"/> 
            </div>
          </div>         
       </div>
    );
  
const textAreaChanged = (item, container) => {
        clearEdited()
        if (item) changedVarList(item, container);
    }   
    let dataUsed = isDraftDoc && (!!value || !required) ||
         !isDraftDoc && (uncheckUsed || usedVars.has(id) || condParts.has(id));
    
    return (
    <div styleName="variableField" key={id} data-edited={editedVar === id} 
      data-id={id} data-hi={hiVar === id && editedVar !== id} data-pos={posVar === id && editedVar !== id}
      data-filled={isDraftDoc && !!value}
      onMouseEnter={() => overVar(id, true) }
      onMouseLeave={() => overVar(id, false) }
      >
    { isDraftDoc ?
    <>
      <div styleName="variableField_docHead" 
        onClick={() => setEdited(id)} >
        <div styleName="variableField_head_inputs" data-used={dataUsed}>
          <div styleName="questionDiv" data-req={required}>{(title ? title : name) + (required ? " *" : "")}</div>
          {hint && (editedVar === id || !value) && <div styleName="hintDiv">{hint}</div>}
        </div>
        {editedVar === id 
         ? (type === "date") ? (
            <div styleName="valueDate" 
              onClick={(e) => dispatch(showDateControl(
                0, 0, el, false
              ))}
              >
              {value ? value : transS('Еnter date')}
              {!!(dateControl?.fromParagraph === false &&
                dateControl?.dateVar.id === el.id) && 
                <DateControl callback={val => {
                  if (val) {
                    el.value = val;                    
                    chgVarList(varlist, el)                    
                  }                 
                }} />}
            </div>
          ) :
         (type !== "dropdown" && <> 
           <div className="space20"></div>
           <TextArea name="value" big="2" 
            defValue={false}            
            saved="1"
            check={type === "number" ? (a) => isNaN(a): false}
            container={el} ronly={false} changed={textAreaChanged} />
         </>)
         : (value && <div styleName="valueDiv">{
           type === "dropdown" ? dropdown.find(d => d.id == value).option : value
           }</div>) }
      </div>
      { editedVar === id && type === "dropdown" && dropdownPart(dropdown) }
    </> :
    <div styleName="variableField_head"
         onClick={() => editedVar !== id ? setEdited(id) : null}>
      <div styleName="variableField_head_inputs" data-used={dataUsed}>        
         <TextArea name="title" placeholder={transS('Enter Question')} big="1"
           inFocus={editedVar === id}
           container={el} ronly={rOnly} changed={changedVarList} 
           required={required} used={!!el.name}
           styleCorrect={getLanguage() ? '' : ' correctWidth'} 
         />
         {(!!hint || editedVar === id) && <TextArea name="hint" 
           placeholder={transS('Enter Hint')}
           styleCorrect={getLanguage() ? '' : ' correctWidth'}  
           container={el} ronly={rOnly} changed={changedVarList} 
         /> }
      </div>
      <div styleName="variableField_head_right">
        {(editedVar === id || rOnly ) ? 
           <CheckBox filled={required}
            text={`${transS('Required')} *`}  ronly={rOnly} 
            changed={() => { if (rOnly) return;
               el.required = !required; changedVarList("required", el);}} />     
        : <div styleName="variableField_head_right_clpsBtns">
          {insertButton()}
          <button onClick={() => {setEdited(id)} }>{transS('Edit')}</button>
        </div> }
      </div>
    </div> }
    {!isDraftDoc && (editedVar === id) && <>
       {type === 'dropdown' && dropdownPart(dropdown)}
       {showAdvanced && advancedPart()}
       <div styleName="variableField_footer">
         {insertButton()}
         {!rOnly && <button styleName="variableField_saveBtn"
           onClick={clearEdited}>{transS('Save')}</button>}
         {!rOnly && showAdvanced ? <div styleName="variableField_footer_advancedPart">
            <div styleName="variableField_footer_move">
               <button styleName="variableField_disabledBtn">
                  {transS('Add to Group')}
                </button>
               <button styleName="variableField_saveBtn" onClick={() => cloneVar(el)}>
                 {transS('Clone')}
                </button>
            </div>
            <div styleName="variableField_footer_move">
                <button onClick={() => moveUp(true)}>{transS('Move Up')}</button>
                <button onClick={() => moveUp(false)}>{transS('Move Down')}</button>
            </div>
          <button styleName="variableField_delBtn" onClick={removeVar}>{transS('Delete')}</button>
         </div>
         : !showAdvanced && <>
           <button onClick={() => setShowAdvanced(true)}>{transS('Advanced')}</button>
           <DropDownType type={type} container={el} 
             changed={(defaultValue || dropdown || rOnly) ? null : () => changedVarList('',el)}
             func={_getDropDown}/>
         </>}
       </div>
    </>}
    </div>
    );
  };

  const checkCondition = cond => {
    const c = cond.condVar, o = cond.condOption,
      vr = varlist.find(el => el.id === c)
    return vr?.value === o
  }
  
  // main part
  if (viewedNew && viewedNew != lastViewed && !rOnly ) {
    setLastViewed(viewedNew);
    if (varlist && varlist[0]) {
     if (!varlist[0].name) {
       varlist[0].name = checkUnique('');
       setEdited(varlist[0].id);
       // chgVarList(varlist, varlist[0]);
     }
    }
  }
  
    return (
    <div styleName="varContainerExtAdapter">
    <div styleName='varContainerExt' ref={containerRef}>
        <ScrollBar style={{
                height: '100%',
                width: 'calc(100% - 8px)',
                zIndex: '10',
                backgroundColor: '#ffffff'
                }} invisible="true">
      <div styleName='varContainer'>
        <div styleName="head">
          <div styleName="head_title">{isDraftDoc ? transS('Questions') : transS('Variables')}</div>
          <div styleName="head_radio">
            {radioElement("project", isDraftDoc ? transS("All") : transS('Created for Project'))}
            {isDraftDoc ? radioElement("Unanswered", transS('Unanswered'))
              : radioElement("global", transS('Global'))}
            {!isDraftDoc && radioElement("used", transS('Used in Project'))}
          </div>
        </div> 
        {varlist && varlist.filter(el => 
              (viewVars === "project") &&
                (!isDraftDoc || !el.conditional || condParts.has(el.id))
           || (viewVars === "used") && 
               !uncheckUsed && (usedVars.has(el.id) || condParts.has(el.id))
           || (viewVars === "Unanswered") && !el.value &&
                (!isDraftDoc || !el.conditional || condParts.has(el.id)))
        .map( el => varItem(el))
       }
        <div className="space50"></div>
      </div>
       </ScrollBar>
      </div>
      </div>
    )
}

export default CSSModules(Variables, styles, {allowMultiple: true});

