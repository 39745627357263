import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './formatting.css';
import { setPreviousColor } from '../../../../redux/paragraph/actions';
import { shdColors, standartTextColors } from '../../../../../configs/appConfig';
import { transS } from '../../../../services/helpers/lang';


const BIH = ({sendToFrame, isBold, isItalic}) => {
  const {formatting, previousColor} = useSelector(state => state.paragraphReducer)
  const [showColors, setShowColors] = useState(false)
  const [showTextColors, setShowTextColors] = useState(false)
  const 
    selected = b => b ? " selected" : "",
    formatable = !!formatting?.fontFamily,
    dispatch = useDispatch(),
    colorRef = useRef(null),
    textColorRef = useRef(null)

    const outsideClickHandler = e => {
        if (colorRef.current && colorRef.current.style.display !== 'none'
             && !colorRef.current.parentElement.contains(e.target)) 
            {
            setShowColors(false)
        } else if (textColorRef.current && textColorRef.current.style.display !== 'none'
          && !textColorRef.current.parentElement.contains(e.target)) 
         {
          setShowTextColors(false)
     }
    }
      
    useEffect(() => {
        window.addEventListener('click', outsideClickHandler);
        return () => {
          window.removeEventListener('click', outsideClickHandler);
        };
    }, []);
    

  const setHighlight = (number, formatable) => {
    dispatch(setPreviousColor(number))
    sendColorObj(number, formatable)
    setShowColors(false)
  }

  const setTextColor = (number) => {
    sendToFrame({ c1: "format", format: {color: standartTextColors[number]}})
    setShowTextColors(false)
  }

  const sendColorObj = (number, formatable) => sendToFrame({ c1: "format", 
    format: {backgroundColor: shdColors[number]},
    color: formatable ? null : number })

  useEffect(() => setShowColors(false), [formatting])

  return <div styleName="BIH">
    <span
      styleName={"sign bold clickable" + selected(isBold)}
      onClick={() => sendToFrame({
          c1: "format", 
          format: { fontWeight: isBold ? "normal" : "bold" },
          color: formatable ? null : 'b'
        } )}>B</span>
    <span
      styleName={"sign italic clickable" + selected(isItalic)}
      onClick={() => sendToFrame({
          c1: "format",
          format: { fontStyle: isItalic ? "normal" : "italic" },
          color: formatable ? null : 'i'
        } )}>I</span>
    <div styleName="back-picker clickable">
      <svg onClick={() => sendColorObj(previousColor, formatable)}
        width="15" height="15" viewBox="0 0 40 40">
        <g fill="none" fillRule="evenodd">
          <path fill="#157EFB" d="M10 0 V40 H40 V0" />
          <path fill="#157EFB" d="M0 5 V35 H6 V5" />
          <path fill="#157EFB" d="M0 17 H10 V24 H0" />
          <path fill="#FFFFFF" d="M10 17 H18 V24 H10" />
          <path fill="#FFFFFF" d="M17 5 V35 H23 V5" />
        </g>
      </svg>
      <span onClick={() => sendColorObj(previousColor, formatable)}
        styleName="pickColor"
        style={{ backgroundColor: shdColors[previousColor] }}
      ></span>
      <div className={"arrow" + (showColors ? " arrowOpen" : "")}
         onClick={() => setShowColors(!showColors)}></div>
      <div style={{display: showColors ? 'block' : 'none'}}
        styleName='highlights' ref={colorRef}>
          <div styleName='noHighlight clickable'
            onClick={() => setHighlight(0, formatable)}>{transS('No Highlight')}</div>
          <div style={{display: 'flex'}}>
            <div styleName='mainHighlight clickable' 
              style={{backgroundColor: shdColors[1]}} 
              onClick={() => setHighlight(1, formatable)}></div>
            <div styleName='smallHighlights'>
              {shdColors.map((el, i) => (i > 1) && 
                <div styleName='clickable' 
                  key={i} style={{backgroundColor: el}} 
                  onClick={() => setHighlight(i, formatable)}></div>)}
            </div>
          </div>
        </div>
    </div>
    {/* Пока что отключаем !!!FALSE!!! изменение цвета текста */}
    {!!formatting && false && <div styleName={"clickable"} style={{backgroundColor: formatting.color, width: 10, height: 10, position: "relative", left: 10}}
      onClick={() => {setShowTextColors(!showTextColors)}}
    >
      <div style={{display: showTextColors ? 'block' : 'none'}}
        styleName='highlights' ref={textColorRef}>
          {/* <div styleName='noHighlight clickable'
            onClick={() => setTextColor(0)}>{transS('No Highlight')}</div> */}
          <div style={{display: 'flex'}}>
            <div styleName='mainHighlight clickable' 
              style={{backgroundColor: standartTextColors[0]}} 
              onClick={() => setTextColor(0)}></div>
            <div styleName='smallHighlights'>
              {standartTextColors.map((el, i) => (i > 0) && 
                <div styleName='clickable' 
                  key={i} style={{backgroundColor: el}} 
                  onClick={() => setTextColor(i)}></div>)}
            </div>
          </div>
        </div>
    </div>}
  </div>
}
export default new CSSModules(BIH, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});