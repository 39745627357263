import { dataConstants } from '../constants';
import { popUpReset, popUpWithInputReset } from '../helpers/index';

const initialState = {
  popUp: popUpReset(),
  popUpWithInput: popUpWithInputReset(),
  loader: false,
  showStarted: false,
  deletedProjectId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
  case dataConstants.GET_POP_UP:
    return {
      ...state,
      popUp: action.popUp
    };
  case dataConstants.GET_POP_UP_WITH_INPUT:
    return {
      ...state,
      popUpWithInput: action.popUpWithInput.popup
    };
  case dataConstants.TOGGLE_LOADER:
    return {
      ...state,
      loader: action.loader
    };
  case dataConstants.SHOW_DELETED_PROJECT_POPUP:
    return {
      ...state,
      popUp: action.popUp
    };
  case dataConstants.SHOW_STARTED:
    return {
      ...state,
      showStarted: action.showStarted
    };
  default:
    return state;
  }
};
