import React, { Component } from 'react';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import moment from 'moment';
import cx from 'classnames';
import styles from './paragraphList.css';
import { getUserGroup, setNewClause } from '../../../../services/actions/userActions';
import { toggleLoaderAction, getPopUpAction } from '../../../../services/actions/dataActions';
import {setCurrentProjectAndProjects, setCurrentProject, getProjectDocuments} from '../../../../services/actions/projectActions';
import { TemplatesActionCreators } from '../../../../redux/template/action';
import { hasMemberInternalGroupPermission, oldPermissionCheck } from '../../../../../common/validation';
import {
    setFrameAction,
    resetPragraphListAction,
    setMenuAction,
    setPopupMode,
    setJumpChanges,
    unreadTasksAction,
    calculateUnreadTasksAction,
    setNoticeBannerContent,
} from '../../../../services/actions/paragraphActions'
import {
  turnInsertModeAction,
  turnFormattingAction,
  blockParagraphsAction,
  turnCommunicationVis,
  setParagraphStyles,
} from '../../../../redux/paragraph/actions';
import {
    setCurrentDocumentAction,
    setActualDocumentAction,
    getHistoryDocumentAction, 
    setDisplayOnlyAction,
    getActualDocumentAction, setDocumentNavigationAction,
    setFormatable,
    showDateControl,
    setUsedVars,
    setVarClick,
    setVarChange,
} from '../../../../redux/document/actions';
import Spinner from '../../../../components/spinner/Spinner';
import api from '../../../../services/api/api';
import {DocStats} from "./DocStats"
import {getDocStatsAction} from "../../../../services/actions/documentActions"
import {popUpReset} from "../../../../services/helpers"
import {DocumentNavigation} from "../../components/documentNavigation/documentNavigation"
import {saveStats, eventTypes, eventSubtypes} from "../../../../services/helpers/logger"
import {iframeSetContent} from '../../../../frame/initFrame'
import OverlineMenu from './OverlineMenu';
import { transL, transS } from '../../../../services/helpers/lang';
import Clause from '../clauseLib/Clause';
import DateControl from '../../components/dateControl/DateControl';
import Ruler from './Ruler';
@connect(state => ({
  user: state.userReducer.user,
  editClause: state.userReducer.editClause,
  dateData: state.userReducer.dateData,
  externalUserList: state.userReducer.externalUserList,
  internalUserList: state.userReducer.internalUserList,
  paragraphList: state.paragraphReducer.paragraphList,
  commentList: state.paragraphReducer.commentList,
  changesList: state.paragraphReducer.changesList,
  paraTitles: state.paragraphReducer.paraTitles,
  redos: state.paragraphReducer.redos,
  projectList: state.projectReducer.projectList,
  templatesList: state.templateReducer.templatesList,
  listReady: state.paragraphReducer.listReady,
  frameContent: state.paragraphReducer.content,
  stateMenu: state.paragraphReducer.stateMenu,
  paragraphToFocus: state.paragraphReducer.paragraphToFocus,
  currentProject: state.projectReducer.currentProject,
  invitePopup: state.projectReducer.invitePopup,
  actualDocument: state.document.actualDocument,
  currentDocument: state.document.actualDocument,
  displayOnly: state.document.displayOnly,
  displayVersion: state.document.displayVersion,
  tempBlock: state.paragraphReducer.tempBlock,
  popUp: state.dataReducer.popUp,
  docStats: state.document.docStats,
  dateControl: state.document.dateControl,
  popupMode: state.paragraphReducer.popupMode,
  messages: state.projectReducer.currentProject.messages,
  communicationVis: state.paragraphReducer.communicationVis
}), {
  getUserGroup,
  setNewClause,
  setFrameAction,
  setMenuAction,
  resetPragraphListAction,
  blockParagraphsAction,
  turnCommunicationVis,
  turnInsertModeAction,  
  turnFormattingAction,
  setParagraphStyles,
  toggleLoaderAction,
  setCurrentDocumentAction,
  setActualDocumentAction,
  getPopUpAction,
  getHistoryDocumentAction,
  setDisplayOnlyAction,
  setCurrentProjectAndProjects, getProjectDocuments, setCurrentProject,
  getActualDocumentAction, getDocStatsAction, setPopupMode, setJumpChanges, unreadTasksAction,
  setNoticeBannerContent,
  setFormatable,
  showDateControl,
  setUsedVars,
  setVarClick,
  setVarChange,
    setDocumentNavigationAction,
    saveStats, calculateUnreadTasksAction, 
})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class ParagraphList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      versionBarIsOpen: false,

      selectedTime: 6,
      paraFilter: 2,
      sideFilter: 2,
      docStatsError: false,
      docStats: null,
      isFrameVis: true,
      lastEventTimestamp: 0, // event: mousemove || scroll

      overlineParams: {},
      communicationVis: 0,
      jumpToCommunicationMessage: null,
    };
    this.loaded = false;
    this.history = null;
    this.tasks = [];
    this.openTimestamp = 0
    this.readingCheckInterval = null
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    let { projectList, setCurrentDocumentAction, match, getActualDocumentAction, 
        blockParagraphsAction, setDisplayOnlyAction, templatesList, setCurrentProject,
        getProjectDocuments, setDocumentNavigationAction, user, setActualDocumentAction } = this.props;

    setDisplayOnlyAction(false, '');
    if (this.props.location.pathname.includes('/template/')) {
        let templates = templatesList;
        if (!templates || !templates.length) {
            templates = await TemplatesActionCreators.fetchTemplatesImmediate();
        }
        const template = templates.find(el => el._id === match.params.templateId)
        setCurrentDocumentAction(template)
        getActualDocumentAction({ documentId: template?._id, projectId: null, preventChangeRoute: true });
    } else {
      if (!projectList || !projectList.find(item => item._id === match.params.projectId)) {
        await getProjectDocuments();
      }
      setCurrentProject({projectId: match.params.projectId, 
        projectList: this.props.projectList});
      setCurrentDocumentAction(match.params.documentId);
      getActualDocumentAction({ documentId: match.params.documentId, projectId: match.params.projectId, historyDocumentId: match.params.historyDocumentId, preventChangeRoute: true });     
    }
    mixpanel.track('open doc');
    blockParagraphsAction(true);
    if (this.props.location.search) {
        const queryParams = new URLSearchParams(this.props.location.search)
        const envelopeId = queryParams.get("envelopeId")
        const envelopeId1 = queryParams.get("env")
        const event = queryParams.get("event")
        if (event === 'popup') {
            let diffMail = queryParams.get("email");
            this.props.getPopUpAction({
                      name: 'confirm',
                      header: diffMail ? 'Wrong DocuSign account' : 'Error with DocuSign account',
                      text: diffMail ?
                          `You logged in with ${diffMail}.  <br><br> Please sign in to DocuSign with ${user.email} or go to ${user.email} and sign via the link from email.`
                        : `Please sign with the link that was sent to your email ${user.email}.`,
                      cancel: {
                          name: transS('Got it'),
                          mod: 'blue arch big',
                          event: () => this.props.getPopUpAction(popUpReset)
                      }
                  })            
        }
        else if (event && event !== 'Save' && (envelopeId || event !== 'Cancel'))
           api.signDocumentCheck(envelopeId || envelopeId1, event, match.params.documentId, match.params.projectId);
        window.history.pushState('','',this.props.location.pathname)
    }


    setDocumentNavigationAction(null, null)
    this.readingCheckInterval = setInterval(() => {
        const isUserSleep = Date.now() - this.state.lastEventTimestamp > 120000 // 2 минуты без движения мышки или скролла - юзер ушёл в "сон"
        if ( (document.hidden || isUserSleep || this.state.popupMode) && this.openTimestamp) { // свернули браузер/переключили вкладку или юзер "уснул"

            this.props.saveStats({type: eventTypes.DOCUMENT, subtype: eventSubtypes.READING_DOCUMENT, project: this.props.currentProject._id,
                document: this.props.actualDocument.coreDocument, when: Date.now(),
                attributes: {duration: Date.now() - this.openTimestamp, version: this.props.actualDocument.version, group: this.props.user.userGroup}})

            return this.openTimestamp = 0
        }
        if (!this.openTimestamp && !document.hidden && !isUserSleep && !this.state.popupMode) {
            this.openTimestamp = Date.now()
        } // снова открыли браузер/вкладку или юзер "проснулся"
    }, 15000)
    window.addEventListener('mousemove', this.userEventHandler)
    window.addEventListener('scroll', this.userEventHandler)
    window.addEventListener('unload', this.pageUnloadHandler)
    window.addEventListener('keydown', this.pageKeyHandler)
    this.openTimestamp = Date.now()
  }
  
  pageKeyHandler = (e) => {
    if (this.ifr && e.target.tagName !== 'TEXTAREA' && e.target.tagName !== 'INPUT'
      && (e.ctrlKey || e.metaKey) && ((e.code === "KeyZ") || (e.code === "KeyY")) ) {
        this.sendToFrame({c1: e.code === "KeyZ" ? 'undo' : 'redo'});
      e.preventDefault();
      e.stopPropagation();
    }
  }

  userEventHandler = () => {
      if (Date.now() - this.state.lastEventTimestamp < 2000) return
      this.setState({ lastEventTimestamp: Date.now() } )
  }
  pageUnloadHandler = () => {
      if (!this.openTimestamp) return
      this.props.saveStats({type: eventTypes.DOCUMENT, subtype: eventSubtypes.READING_DOCUMENT, project: this.props.currentProject._id,
          document: this.props.actualDocument.coreDocument, when: Date.now(),
          attributes: {duration: Date.now() - this.openTimestamp, version: this.props.actualDocument.version, group: this.props.user.userGroup}})
  }

  componentWillUnmount() {
    const { setCurrentDocumentAction, resetPragraphListAction, 
        setNoticeBannerContent,
        setActualDocumentAction, setDisplayOnlyAction, setJumpChanges } = this.props;
    window.removeEventListener("message", this.handleFrameTasks);
    window.removeEventListener('scroll', this._frameScroll);
    window.removeEventListener("resize", this.handleResize)

    clearInterval(this.readingCheckInterval)
    window.removeEventListener('mousemove', this.userEventHandler)
    window.removeEventListener('scroll', this.userEventHandler)
    window.removeEventListener('unload', this.pageUnloadHandler)
    window.removeEventListener('keydown', this.pageKeyHandler)

    this.ifr = null;
    resetPragraphListAction();
    setCurrentDocumentAction(null);
    setActualDocumentAction();
    setDisplayOnlyAction(false, '');
    setJumpChanges({vis: false})
    setNoticeBannerContent(null)
    this.pageUnloadHandler() // вышли из документа
  }
  
  sendToFrame = (data) => {
      this.ifr.contentWindow.postMessage(JSON.stringify(data), document.location.origin);
  }

  handleResize = () => {
    if (!this.ifr)
        return
    const frameRem = 
        parseFloat(getComputedStyle(this.ifr.contentDocument.documentElement).fontSize),
        myRem = parseFloat(getComputedStyle(document.documentElement).fontSize)
    if (frameRem === myRem)
        return
    this.ifr.contentDocument.documentElement.style.fontSize = `${myRem}px`
  }

  handleFrameTasks = (e) => {
      const { match, paragraphList, projectList, currentProject, commentList, paragraphToFocus, user, actualDocument,  
        displayOnly, displayVersion, getActualDocumentAction, blockParagraphsAction, 
        turnCommunicationVis, turnInsertModeAction, turnFormattingAction, setJumpChanges,
        getHistoryDocumentAction, externalUserList, internalUserList, setMenuAction, stateMenu, unreadTasksAction,
          setDocumentNavigationAction, dateData, redos, setParagraphStyles } = this.props;
      if (!e.data || (typeof(e.data) != "string") ) return;
      let msg = JSON.parse(e.data);
      if (msg.c1) {
          switch (msg.c1) {
              case 'frame':
                  const internalUserList = this.props.location.pathname.includes('/template/') ? [] : this.props.internalUserList
                  blockParagraphsAction(true);
                  let checkUserList = externalUserList && Array.isArray(externalUserList) ? externalUserList : [];
                  let ext = checkUserList && checkUserList.length
                      ? externalUserList.map(item => {
                          return {
                              id: item._id, info:
                                  {
                                      avatar: item.avatar, firstname: item.firstname, lastname: item.lastname
                                      , isActivated: item.isActivated, email: item.email, ext: 1
                                  }
                          };
                      }) : [];
                  let int = internalUserList.map(item => {
                      return {
                          id: item._id, info:
                              {
                                  avatar: item.avatar, firstname: +item.email ? 'New User' : item.firstname,
                                  lastname: item.lastname, ticks: item.ticks, email: +item.email ? '' : item.email
                              }
                      };
                  });
                  let created = '', isCollaborative = actualDocument.orig == 6 
                    || actualDocument.orig == 7;
                  if (displayVersion) {
                      let el = actualDocument.history.find(el => {
                          return el.version + "." + el.subVersion === displayVersion
                      });
                      if (el) created = el.createdAt;
                      isCollaborative = false;
                  }
                  let new_history;
                  if (!actualDocument.history)
                      new_history = actualDocument.new_history + '\n'
                          + JSON.stringify({
                              editorId: actualDocument.editorId,
                              version: actualDocument.version,
                              subVersion: actualDocument.subVersion,
                              orig: actualDocument.orig,
                              timestamp: +moment(actualDocument.createdAt),
                              editorsGroup: actualDocument.editorsGroup
                          });
                  else {
                      new_history = actualDocument.history.reduce((acc, item) => {
                          return `${acc}\n{"editorId":"${item.editor._id}","version":${item.version},"subVersion":${item.subVersion},"orig":${item.orig},"timestamp": ${+moment(item.createdAt)}, "editorsGroup": "${item.editorsGroup}"}`;
                      }, '');
                      if (new_history) new_history = new_history.slice(1);
                  }
                  this.sendToFrame({
                          c1: 'main',
                          coreDocumentId: actualDocument.coreDocument,
                          myId: user._id,
                          lang: user.lang,
                          myGroup: user.userGroup,
                          dateData: dateData,
                          blocked: !isCollaborative && (displayOnly || actualDocument.blocked || actualDocument.readonly),
                          isDraftDoc: actualDocument.orig === 302,    
                          created: created,
                          ext: ext,
                          int: int,
                          isProhibitPrivate: (user.ticks <= 0) && !hasMemberInternalGroupPermission(),
                          permissionPrivate: hasMemberInternalGroupPermission(),
                          publicComments: paragraphList,
                          privateComments: commentList,
                          halfScreen: window.innerHeight * 0.4,
                          locks: paragraphToFocus,
                          redos: redos,
                          new_history: new_history,
                          dochanges: actualDocument.dochanges,
                          udochanges: user.docs && user.docs[actualDocument.coreDocument],
                          variables: actualDocument.variables && JSON.parse(actualDocument.variables),
                          showedChanges: 0,
                          askChanges: actualDocument.version == 1,
                          jumpPoint: match.params.itemId
                      });

                  this._frameScroll();
                  // this.setState({blankFrame: false});

                  let setMenuParams
                  if ((localStorage.getItem('paragraphStateMenu') || '').includes(actualDocument.coreDocument)) {
                      setMenuParams = JSON.parse(localStorage.getItem('paragraphStateMenu'))[actualDocument.coreDocument]
                  } else {
                      setMenuParams = {showChanges: 0, radioBtn: 0}
                  }
                  if (actualDocument.orig === 9 || actualDocument.orig === 10 
                    || actualDocument.orig === 12 || actualDocument.orig === 13 
                    || actualDocument.orig === 14) {
                      let currentProjectCreator = null
                      projectList.forEach(project => (project._id === currentProject._id) ? currentProjectCreator = project.creator : null)
                      const isExternal = !internalUserList.find(u => u._id === currentProjectCreator)
                      if (actualDocument.orig === 10 && isExternal || actualDocument.orig === 9 && !isExternal)
                          setMenuParams.showChanges = 3
                      else if (actualDocument.orig === 10 && !isExternal || actualDocument.orig === 9 && isExternal)
                          setMenuParams.showChanges = 3
                      else if (actualDocument.orig === 12 || actualDocument.orig === 13 || actualDocument.orig === 14)
                          setMenuParams.showChanges = 3
                  }
                  setMenuParams.undo = false; setMenuParams.redo = false; 
                  setMenuAction(setMenuParams)
                  ;
                  break;
              case 'height':
                  if (this.ifr)
                      this.ifr.height = msg.height;
                  if (msg.rem) {
                    const myRem = parseFloat(getComputedStyle(document.documentElement).fontSize)
                    this.ifr.contentDocument.documentElement.style.fontSize = `${myRem}px`
                  }
                  if (msg.pos) window.scroll(0, msg.pos);
                  if (msg.init) {
                      blockParagraphsAction(false);
                      api.unreadMarks(actualDocument._id, '').then(res => {
                          if (!window.location.pathname.includes('/template/')) {
                              this.props.calculateUnreadTasksAction(res.data.data.list.split(' '))
                          }
                          this.sendToFrame({
                              c1: 'marks',
                              list: res.data.data.list
                          });
                      })
                  }
                  break;
              case 'scroll':
                  if (msg.offset) this.scrollMe(msg.offset);
                  else if (msg.scrollTo) window.scrollTo(0, msg.scrollTo)
                  else window.scrollBy(0, msg.offsetRel);
                  break;
              case 'setmenu':
                  setMenuAction(msg.action);
                  //window.scrollTo(0, 0);
                  break;
              case 'jump':
                  if (this.props.popupMode) this.setPopupMode(false)
                  if (msg.pos) window.scroll(0, msg.pos);
                  else getHistoryDocumentAction(msg.documentId, msg.historyDocumentId, msg.blocked, msg.version, msg.id);
                  this._toggleBar(false);
                  break;
              case 'unHideComments':
                this.toggleCommunicationHandler(0);
                break;
              case 'clause': 
                this.props.setNewClause(msg.quote, this.props.user._id, 
                  msg.shift, () => this.props.setNewClause(null))
                break
              case 'jumpChanges':
                  // if (this.props.popupMode) this.setPopupMode(false)
                  //if (msg.pos) window.scroll(0, msg.pos) // резкий скролл
                  if (msg.pos) window.scrollTo({ // плавный скролл
                      top: msg.pos,
                      behavior: "smooth"
                  })

                  setJumpChanges({end: msg.end, vis: msg.vis})
                  break
              case 'marks':
                  api.unreadMarks(actualDocument._id, msg.list)
                  this.props.calculateUnreadTasksAction(msg.list.split(' '))
                  break;
              case 'unreadTasks':
                  unreadTasksAction(actualDocument.coreDocument, msg.commentId, this.props.user.userGroup)
                  break
              case 'readpars':
                  let p = projectList.find(p => p._id === currentProject._id);
                  if (p && p.docs) {
                      let d = p.docs.find(d => d.coreDocument === actualDocument.coreDocument);
                      if (d && d.dochanges)
                          msg.pars.forEach(no => {
                              if (d.dochanges[no]) {
                                  if (!user.docs) user.docs = {};
                                  if (!user.docs[d.coreDocument]) user.docs[d.coreDocument] = {};
                                  user.docs[d.coreDocument][no] = d.dochanges[no];
                              }
                          });
                  }
                  setMenuAction({changesCount: msg.remains});
                  api.touchDocument(actualDocument.coreDocument, {data: encodeURIComponent(e.data)});
                  break;
              case 'checkpars':
                  setMenuAction({changesCount: msg.remains, hadChanges: 1});
                  break;
              case 'writepars':
                  if (!user.docs) user.docs = {};
                  if (!user.docs[actualDocument.coreDocument]) user.docs[actualDocument.coreDocument] = {};
                  msg.pars.forEach(no => {
                      user.docs[actualDocument.coreDocument][no] = msg.time;
                  });
                  break;
              case 'change':
                    console.log(e.data)
                  api.chgDocument(actualDocument._id, {data: encodeURIComponent(e.data)}).then(res => {
                      const data = res.data; //todo parse error
                  })
                      .catch(resp => {
                          getActualDocumentAction({
                              documentId: actualDocument.coreDocument,
                              projectId: actualDocument.projectId, historyDocumentId: null,
                              preventChangeRoute: true
                          });
                      });
                  break;
              case 'lock':
                  api.lockDocument(actualDocument._id, {data: encodeURIComponent(e.data)}).then(res => {
                      const data = res.data; //todo parse error
                      if (data.data && data.data.failed)
                          this.sendToFrame(data.data);
                  })
                      .catch(resp => {
                          getActualDocumentAction({
                              documentId: actualDocument.coreDocument,
                              projectId: actualDocument.projectId, historyDocumentId: null,
                              preventChangeRoute: true
                          });
                      });
                  break;
              case 'trap':
                  api.trapDocument(actualDocument._id, {data: encodeURIComponent(e.data)});
                  break;
              case 'toFormatPanel':
                  turnCommunicationVis(2); break;
              case 'turnInsertMode':
                  turnInsertModeAction(msg.insertOn, msg.canPaste); break;
              case 'clearFormat':
                  turnFormattingAction(null); break;
              case 'setFormat':
                  turnFormattingAction(msg); break;
              case 'setParagraphStyles':
                  setParagraphStyles(msg); break;
              case 'overlineParams':
                  this.setState({overlineParams: msg}); break;
              case 'restart':
                  getActualDocumentAction({
                              documentId: actualDocument.coreDocument,
                              projectId: actualDocument.projectId, historyDocumentId: null,
                              preventChangeRoute: true
                          }); break;
              case 'comment':
                  if (msg.isFreezedReplies) {
                      this.props.getPopUpAction({
                          name: 'confirm',
                          header: transS('You cannot resolve a thread with an unsent message'),
                          text: transL('delMessages'),
                          cancel: {
                              name: transS('Got it'),
                              mod: 'blue arch big',
                              event: () => this.props.getPopUpAction(popUpReset)
                          }
                      })
                  } else if (!msg.content && msg.isPrivate) {
                      this.props.getPopUpAction({
                          name: 'confirm',
                          text: transS('Are you sure, you want to delete the comment?'),
                          confirm: {
                              name: transS('Delete'),
                              mod: 'red fill big',
                              event: () => {
                                  api.sendComment({
                                      id: actualDocument.coreDocument, listen: actualDocument._id,
                                      content: msg.content, title: msg.title, para: msg.para, comment: msg.comment,
                                      isPrivate: msg.isPrivate, freeze: msg.freeze, activeTimestamp: msg.activeTimestamp
                                  }).then(res => {
                                      const data = res.data;
                                  })
                                      .catch(resp => {
                                          this.sendToFrame({
                                              c1: 'problem',
                                              userId: user._id,
                                              content: msg.content,
                                              time: Date.now(),
                                              para: msg.para,
                                              ocomment: msg.comment,
                                              comment: msg.comment ? msg.comment : Date.now(),
                                              isPrivate: msg.isPrivate,
                                              problem: resp.data
                                          });
                                      });
                                  this.props.getPopUpAction(popUpReset)
                              }
                          },
                          cancel: {
                              name: transS('Cancel'),
                              event: () => this.props.getPopUpAction(popUpReset)
                          }
                      })
                  } else {
                      api.sendComment({
                          id: actualDocument.coreDocument,
                          listen: actualDocument._id,
                          content: msg.content,
                          title: msg.title,
                          para: msg.para,
                          comment: msg.comment,
                          quote: msg.quote,
                          resolve: msg.resolve,
                          isPrivate: msg.isPrivate,
                          freeze: msg.freeze,
                          activeTimestamp: msg.activeTimestamp,
                          recipients: msg.recipients
                      }).then(res => {
                          const data = res.data;
                      })
                          .catch(resp => {
                              this.sendToFrame({
                                  c1: 'problem',
                                  userId: user._id,
                                  content: msg.content,
                                  time: Date.now(),
                                  para: msg.para,
                                  ocomment: msg.comment,
                                  comment: msg.comment ? msg.comment : Date.now(),
                                  isPrivate: msg.isPrivate,
                                  problem: resp.data,
                              });
                          });
                  }

                  break;
              case 'isDocChanged':
                  actualDocument.waiting_isDocChanged = msg.value;
                  break
              case 'navigation':
                  setDocumentNavigationAction(msg.current, msg.paras, msg.isJump, msg.isNeedBack)
                  break
              case 'formatable': this.props.setFormatable(msg.fonts); break;
              case 'dateControl': 
                this.setState({dateVar: msg.dateVar, varlist: msg.varlist})
                this.props.showDateControl(
                msg.left, msg.top, msg.dateVar, true); break;
            
              case 'variable':
                  if (msg.action === 'varChg') { 
                    api.updateTemplate(actualDocument.coreDocument, 'c1 send vars', 
                      undefined, actualDocument._id, msg.variable);
                    this.props.setVarChange(msg.variable);
                  } else if (msg.action === 'checked') {
                      this.props.setUsedVars(msg.usedVars, msg.unusedVars)
                  } else if (msg.action === 'clicked') 
                      this.props.setVarClick(Date.now(), msg.id, msg.option, msg.y - window.scrollY);
                  break;
          }
      }
  }

  _dateCallback = date => {
    if (date) {
        const {dateVar, varlist} = this.state,
          thatVar = varlist.find(el => el.id === dateVar.id)
        thatVar.value = date
        api.updateTemplate(this.props.actualDocument.coreDocument, 'c1 send vars', 
          undefined, this.props.actualDocument._id, this.state.varlist);
        this.props.setVarChange(this.state.varlist);
        this.sendToFrame({c1: 'variable', action: 'list', 
          varlist: this.state.varlist})        
    }
    this.setState({dateVar: null, varlist: null})
  }

  _frameScroll = () => {
      if (this.ifr)
      this.sendToFrame({c1:'position',
          documentHeight: Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight),
          windowHeight: document.documentElement.clientHeight || window.innerHeight || document.getElementsByTagName('body')[0].clientHeight,
          scrollY: window.scrollY
        } );
  }

  scrollMe = offset => {
    const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    const windowHeight = document.documentElement.clientHeight || window.innerHeight || document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffsetToScroll = Math.round(documentHeight - offset < windowHeight ? documentHeight - windowHeight : offset);
    window.scroll(0,destinationOffsetToScroll);
  }

  _toggleBar = value => {
    const { match, getActualDocumentAction } = this.props;

/* todo why ?!
    if (state) {
      getActualDocumentAction({ ...match.params, preventChangeRoute: true, preventListUpdate: true });
    }
*/
    this.setState({
      versionBarIsOpen: value
    });
    if (value) this._dragHistory();
  }

  _dragHistory = () => {
     const { actualDocument, user, paragraphList, commentList, changesList, paraTitles, externalUserList, internalUserList, messages } = this.props;
     let userFound, temp, lastTime = 0, lastTitle, s = (paragraphList && paragraphList.length
        ? paragraphList.replace(/\{\"/g,'{"isPrivate":false,"') + '\n' : '') 
       + (commentList && commentList.length ? commentList.replace(/\{\"/g,'{"isPrivate":true,"') + '\n' : '')
       + (changesList ? changesList : '');        
     const redline = Date.now() - 8*3600000;
     let searchA = [...externalUserList, ...internalUserList], reg = new RegExp(
        `data-due-id="(-?)${user._id}"`), regFound;
     s += actualDocument.history.reduce((acc, item) => {
       if (!item.subVersion && item.createdAt > lastTime) lastTime = item.createdAt + 1;
       return `${acc}{"subver":${item.subVersion},"time": ${moment(item.createdAt)}, "userId": "${item.editor._id}"}\n`;
     }, '\n');
     if (!s) return true;
     let cm = s.split('\n').filter(a => a).map(a => 
      { try {return JSON.parse(a);} catch (e) {return null;} }).filter(a => a);
     cm.sort((a,b)=> !a.comment && b.comment ||
        a.comment && b.comment && (
       (a.comment<b.comment) || (a.comment == b.comment) && (a.time > b.time) ) ? 1 : -1);     
     this.tasks = [];  
     for (let i = 0; i < cm.length; i++) {         
       if (cm[i].comment) {
         if (cm[i].time === cm[i].comment)
          lastTitle = cm[i].title ? cm[i].title 
          : cm[i].content
            .replace(/<br \/>/g,' ')
            .replace(/<br>/g,' ')
            .replace(/<span [^>]+>([^<]+)<\/span>/g,'$1')
            .replace(/(.{13}[^\s\.,\?\!]*)(.*)/,"$1...");         
         cm[i].thTitle = lastTitle; 
         if (cm[i].upd) {
             temp = cm[i].upd;
             cm[i].upd = cm[i].time;
             cm[i].time = temp;
         }
          regFound = cm[i].content.match(reg);
          if (regFound) {
              this.tasks.push(cm[i]);
              let dueTimeFound = cm[i].content.match(new RegExp(`data-due="(\\d+)"`));
              if (dueTimeFound) {
                  cm[i].due = dueTimeFound[1] === '0' ? 0
                         : dueTimeFound[2] ? -1 : cm[i].time + dueTimeFound[1]*1
              } else {
                  cm[i].due = null
              }
          }
       }
         userFound = searchA.find(a => cm[i].userId == a._id );
         if (userFound) {
           cm[i].avatar = userFound.avatar;
           cm[i].firstname = userFound.firstname;
           cm[i].lastname = userFound.lastname;
         } else {
           cm[i].avatar = "";
           cm[i].firstname = transS('The');
           cm[i].lastname = transS('Counterparty');
           //cm[i].time = lastTime;
         }
     }
      if (messages) {
          messages
              .filter(message => !this.tasks.find(t => t.comment === message._id))
              .filter(message => message.document === actualDocument.coreDocument)
              .forEach(message => {
              const messageObj = {
                  isPrivate: !message.isPublic,
                  userId: message.user._id,
                  content: message.content,
                  quote: null,
                  resolve: message.resolve,
                  time: message.when,
                  comment: message._id,
                  thTitle: message.title || '',
                  due: null,
                  avatar: message.user.avatar,
                  firstname: message.user.firstname,
                  lastname: message.user.lastname
              }
              if (message.replies) {
                  message.replies
                      .filter(message => !this.tasks.find(t => t.comment === message._id))
                      .filter(message => message.content.match(reg))
                      .forEach(reply => {
                          const replyObj = {
                              isPrivate: !message.isPublic,
                              userId: reply.user._id,
                              content: reply.content,
                              quote: null,
                              resolve: message.resolve,
                              time: reply.when,
                              comment: message._id,
                              thTitle: '',
                              due: null,
                              avatar: reply.user.avatar,
                              firstname: reply.user.firstname,
                              lastname: reply.user.lastname
                          }
                          const dueTimeFound = reply.content.match(new RegExp(`data-due="(\\d+)"`))
                          if (dueTimeFound) {
                              replyObj.due = dueTimeFound[1] === '0'
                                  ? 0
                                  : dueTimeFound[2] ? -1 : messageObj.time + dueTimeFound[1]*1
                          }
                          this.tasks.push(replyObj)
                  })
              }

              if (!message.content.match(reg)) return

              const dueTimeFound = message.content.match(new RegExp(`data-due="(\\d+)"`))
              if (dueTimeFound) {
                  messageObj.due = dueTimeFound[1] === '0'
                      ? 0
                      : dueTimeFound[2] ? -1 : messageObj.time + dueTimeFound[1]*1
              }
              this.tasks.push(messageObj)
          })
      }
     let approveHistory = []
      actualDocument.approveHistory.forEach(el =>
          approveHistory.push({userId: el.user._id, firstname: el.user.firstname, lastname: el.user.lastname,
              avatar: el.user.avatar, email: el.user.email, time: el.when, action: el.subtype, whoDueChanges: el.attributes ? el.attributes.whoDueChanges : null }))
      cm = [...cm, ...approveHistory]
     this.history = cm.sort((a,b)=> (a.time < b.time) ? 1 : -1);
     if (this.tasks.length) this.tasks = this.tasks.sort((a,b)=> 
       (a.due < b.due) ? (a.due > 0 ? -1 : 1)
         : ( (a.due > b.due) ?  (b.due > 0 ? 1 : -1) 
           : (a.time < b.time) ? 1 : -1  ) );
     return true;
  };
  
  _jumpDoc = (id, documentId, historyDocumentId, blocked, version, isFromBackLink) => {
      let timeout = 0
      if (isNaN(id.split('-')[0])) { // message in communication
          return this.setState({jumpToCommunicationMessage: id.split('-')[0]})
      }
      if (this.state.communicationVis !== 0) {
          this.toggleCommunicationHandler(0)
      }
      if (this.props.stateMenu.radioBtn === 0) {
          this.props.setMenuAction({radioBtn: 2})
          timeout = 300
      }
      setTimeout(() => {
          this.sendToFrame({c1:"jump", id, documentId, historyDocumentId, blocked, version, isFromBackLink});
      }, timeout)
  }

   _jumpDocChanges = (direction) => {
        this.sendToFrame({c1:"jumpChanges", direction});
    }

  _collapseComments = state => {
      this.sendToFrame({c1: 'collapse', toCollapse: state});
  }

  _createVariable = (type) => {
      this.sendToFrame({c1: 'variable', action: 'create', type})
  }
  
  componentDidUpdate(prevProps, prevState) {
    let { user,  actualDocument, displayOnly, projectList, 
        currentProject, match, popupMode, externalUserList, 
        editClause, internalUserList } = this.props;
    
    if (!prevProps.editClause && editClause)
        this.setState({editClauseY: 
            Math.round(editClause.shift + 382 - 114 +scrollY)})

    if (this.props.location.pathname.includes('/template/')) {

    } else {
        if (!prevProps.projectList && !this.props.projectList) {
            this.props.history.push('/not-found/404/');
            return;
        } else if (!prevProps.projectList && this.props.projectList) {
            let newItem = projectList.find(item => item._id === match.params.projectId);
            setCurrentProjectAndProjects({project: newItem, projectList: projectList});
        }

        if (!prevProps.popupMode && this.props.popupMode || prevProps.popupMode && !this.props.popupMode) {
            this.setPopupMode(this.props.popupMode)
        }
    }

    if (this.ifr && (prevProps.dateData !== this.props.dateData)) 
        this.sendToFrame({c1: 'dateData', dateData: this.props.dateData});
    if (!prevProps.listReady && this.props.listReady) {
        let content = this.props.frameContent;
        if (!content.includes('data-version=')) content = content.replace(/font-size/ig,'font-sze'); //for production
        iframeSetContent(content, !!window.location.pathname.includes('/template/'))
        window.addEventListener("message", this.handleFrameTasks)
        window.addEventListener("resize", this.handleResize)
        window.addEventListener('scroll', this._frameScroll)
        this.props.setFrameAction(this.sendToFrame,
            {toggleBar: this._toggleBar, versionBarIsOpen: () => this.state.versionBarIsOpen,
                dragHistory: () => this.history, dragTasks: () => this.tasks,
                jumpDoc: this._jumpDoc,
                prohibitNewVersion: (oldPermissionCheck() && user.ticks <= 0) || displayOnly || actualDocument.blocked,
                collapseComments: this._collapseComments,
                jumpDocChanges: this._jumpDocChanges,
                sendToFrame: this.sendToFrame,
                createVariable: this._createVariable
            });
    }    
  }

  setPopupMode = (value) => {
      const {actualDocument, getDocStatsAction, setPopupMode, popupMode} = this.props
      const {docStatsError} = this.state

      if (value) {
          getDocStatsAction(actualDocument.coreDocument)
              .then( () => {
                  this.sendToFrame({c1: 'docStats', history: this.getStatsHistory(this.state.selectedTime).history })
                  if (docStatsError) this.setState({docStatsError: false})
              } )
              .catch(e => {
                  console.log('getDocStatsAction Error: ', e)
                  this.sendToFrame({c1: 'docStats', action: 'error' })
                  this.setState({docStatsError: true})
              })
      } else {
          this.setState({selectedTime: 6, paraFilter: 2, sideFilter: 2, docStatsError: false, docStats: null, isFrameVis: true})
          this.sendToFrame({c1: 'docStats', action: 'close' })
      }
      if (value !== popupMode) setPopupMode(value)
  }

    statsFilterClickHandler = (action, value) => {
      const {paraFilter, sideFilter} = this.state
        if (!value && value !== 0) return
        switch (action) {
            case 'time':
                this.setState({selectedTime: value})
                this.getStatsHistory(value)
                break;
            case 'para':
                this.setState({paraFilter: value})
                this.sendToFrame({c1: 'docStats', action: 'filter', paraFilter: value, sideFilter})
                break;
            case 'side':
                this.setState({sideFilter: value})
                this.sendToFrame({c1: 'docStats', action: 'filter', paraFilter, sideFilter: value})
                break;
        }
    }

    getStatsHistory = (value = 3) => {
        const {actualDocument, currentProject, user} = this.props
        const isProjectAdmin = currentProject.admins && !!currentProject.admins.find(i => i === user._id)
        let statistics = this.props.docStats.stats.slice(),
            isInternal = !this.props.user.userGroup,
            history = {}, usersActivity = [], counterpartActivity = 0,
            _counterpartUsersActivity = []

        let filteredStats = []
        statistics.forEach(el => {
            let action
            if (el.subtype === 'READING_DOCUMENT') action = 0
            else if (el.subtype === 'EDITING_DOCUMENT') action = 1
            else if (el.subtype === 'DELETE_COMMENT') action = 3
            else action = 2 // 'ADD_COMMENT' 'EDIT_COMMENT'
            
            if (el.attributes.action?.includes('MUTE')) return;

            filteredStats.push({
                document: el.document,
                paragraph: el.attributes.para,
                user: el.user,
                group: el.attributes.group,
                version: el.attributes.version,
                when: el.when,
                action,
                freeze: el.attributes.freeze,
                duration: el.attributes.duration ? el.attributes.duration : 0
            })
        })
        let stats = filteredStats.slice()

        if (!isProjectAdmin) { // не админ видит только свою статистику
            filteredStats = filteredStats.filter(el => el.user._id === user._id)
            stats = stats.filter(el => el.user._id === user._id)
        }

        switch (value) {
            case 0: // this version
                filteredStats = filteredStats.filter(el => el.version === actualDocument.version)
                break;
            case 1:  // previous version
                filteredStats = filteredStats.filter(el => el.version === actualDocument.version - 1)
                break;
            case 2: // today
                const currentDate = new Date(Date.now()).getDate()
                filteredStats = filteredStats.filter(el => currentDate === new Date(el.when).getDate())
                break;
            case 3: // this week
                filteredStats = filteredStats.filter(el => {
                    let current = moment().week()
                    if (moment().day() === 0) --current
                    let elWeek = moment(el.when).week()
                    if (moment(el.when).day() === 0) --elWeek
                    return current === elWeek && moment().year() === moment(el.when).year()
                })
                break;
            case 4: // this month
                filteredStats = filteredStats.filter(el => moment().month() === moment(el.when).month() && moment().year() === moment(el.when).year())
                break;
            case 5: // last month
                filteredStats = filteredStats.filter(el => {
                    let currentMonth = moment().month(), currentYear = moment().year()
                    if (currentMonth === 0) {
                        --currentYear
                        currentMonth = 11
                    } else --currentMonth
                    return currentMonth === moment(el.when).month() && currentYear === moment(el.when).year()
                })
                break;
            case 6: // from start (all)
                // без фильтров
                break;
        }

        filteredStats.forEach(i => {
            if (isInternal && !i.group || !isInternal && i.group) {
                let currentUser = usersActivity.find(user => user.user._id === i.user._id)
                if (!currentUser) {
                    usersActivity.push({user: i.user, readingDuration: 0, editingDuration: 0, commentingDuration: 0})
                    currentUser = usersActivity.find(user => user.user._id === i.user._id)
                }
                if (i.action === 0) currentUser.readingDuration += i.duration
                else if (i.action === 1) currentUser.editingDuration += i.duration
                else if (i.action === 2 && i.duration) currentUser.commentingDuration += i.duration
            } else {
                let currentUser = _counterpartUsersActivity.find(user => user.user._id === i.user._id)
                if (!currentUser) {
                    _counterpartUsersActivity.push({user: i.user, readingDuration: 0, editingDuration: 0, commentingDuration: 0})
                    currentUser = _counterpartUsersActivity.find(user => user.user._id === i.user._id)
                }
                if (i.action === 0) currentUser.readingDuration += i.duration
                else if (i.action === 1) currentUser.editingDuration += i.duration
                else if (i.action === 2 && i.duration) currentUser.commentingDuration += i.duration
            }
        })
        _counterpartUsersActivity.forEach(i => {
            let reading = i.readingDuration - i.editingDuration - i.commentingDuration
            if (reading < 0) reading = 0
            const total = reading + i.editingDuration + i.commentingDuration
            counterpartActivity += total
        })

        stats.forEach(i => {
            if (i.freeze) return // неотправленные комментарии не должны идти в общее кол-во

            const paragraph = i.paragraph

            const check = history.hasOwnProperty(paragraph)
            if (!check) history[paragraph] = {paragraph, intEdits: 0, extEdits: 0, intComments: 0, extComments: 0}

            if (isInternal) {
                if (!i.group) {
                    i.action === 1 ? history[paragraph].intEdits += 1 : null
                    i.action === 2 ? history[paragraph].intComments += 1 : null
                    i.action === 3 ? history[paragraph].intComments -= 1 : null
                } else {
                    i.action === 1 ? history[paragraph].extEdits += 1 : null
                    i.action === 2 ? history[paragraph].extComments += 1 : null
                }
            } else {
                if (i.group) {
                    i.action === 1 ? history[paragraph].intEdits += 1 : null
                    i.action === 2 ? history[paragraph].intComments += 1 : null
                    i.action === 3 ? history[paragraph].intComments -= 1 : null
                } else {
                    i.action === 1 ? history[paragraph].extEdits += 1 : null
                    i.action === 2 ? history[paragraph].extComments += 1 : null
                }
            }
        })

        const docStats = {
            history,
            usersActivity,
            counterpartActivity,
            addedBy: this.props.docStats.addedBy,
            createdAt: this.props.docStats.createdAt
        }
        this.setState({docStats})
        return docStats
    }

    toggleCommunicationHandler = val => {
      this.sendToFrame({c1: 'communication', communicationVis: val})
      this.setState({communicationVis: val})
    }

    toggleCommentSide = val => {
        this.sendToFrame({c1: 'toggleCommentSide', commentSide: val})
    }

  render() {
    let { listReady, user, projectList, invitePopup, getPopUpAction, 
        actualDocument, displayOnly, tempBlock, popUp, popupMode, 
        editClause, currentProject, dateControl } = this.props;
    const { versionBarIsOpen, docStatsError, selectedTime, paraFilter, 
        sideFilter, isFrameVis, 
        overlineParams, 
        communicationVis, jumpToCommunicationMessage } = this.state;
    let isInvitePopupOpen = invitePopup && invitePopup.show && user && user._id;
      // let suiteCase = invitePopup && invitePopup.show && user && user._id &&
      //     (user.plan && user.plan.includes('suite'));
    const spinner = () => 
        <div style={{position: 'fixed', width: '100%', height: '100%', top: '0', left: '0', background: 'white'}}><Spinner /></div>;
    const inTemplate = this.props.location.pathname.includes('/template/');
    const isDraftDoc = actualDocument.orig === 302;
    if ((!projectList && !inTemplate) || !listReady) {
      return spinner();
    }

    /*if (popUp && popUp.name === 'confirm') {
      return false;
      // src={`/api/documents/getdoc/${actualDocument.projectId}/${actualDocument._id}/${actualDocument.blocked ? 1 : 0}`} width="100%" height="15000" 
    }*/
    
    return (
      <div styleName={cx('wrapper', {'_unvisible': isInvitePopupOpen && false, 'popupMode': popupMode})}>
          <div styleName={cx({'popupModeWindow': popupMode})}>
          <div styleName='rulerWrapper'>{this.props.communicationVis == 2 && <Ruler />}</div>
          {popupMode && <span onClick={ () => this.setPopupMode(false) } className='close-cross'>+</span>}
          {popupMode && <DocStats filter={this.statsFilterClickHandler} selectedTime={selectedTime} paraFilter={paraFilter} sideFilter={sideFilter}
                                  setFrameVis={ value => this.setState({isFrameVis: value}) }
                                  isProjectAdmin={currentProject.admins && !!currentProject.admins.find(i => i === user._id)}
                                  actualDocument={actualDocument} docStats={this.state.docStats} isError={docStatsError} user={user} />}
          {!popupMode && !inTemplate && !isDraftDoc 
              && <DocumentNavigation jump={ (id, isFromBackLink) => this._jumpDoc(id, actualDocument._id, null, null, null, isFromBackLink)}/>}
           <div styleName='preframe'>
               <OverlineMenu params={overlineParams} sendToFrame={this.sendToFrame} />
               {!!(editClause || dateControl) && <div styleName='clauseScreen' 
                 onClick={() => {editClause.modifyClause(null);
                     this._dateCallback(null)}}></div>}                 
               {!!editClause && <Clause 
                 kind={editClause.kind || "clauseDraft"}
                 clause={editClause.clause}
                 callback={editClause.modifyClause}
                 shift={this.state.editClauseY}
                 rOnly={editClause.rOnly}
               />}
               {!!dateControl?.fromParagraph 
                 && <DateControl callback={this._dateCallback}/>}
           </div>
       <iframe width="100%" height="15000" name='iframe' src='/templates/frame.html'
               style={( (popupMode && !this.state.docStats && !docStatsError) || !isFrameVis) ? {display: 'none'} : null}
       ref={(f) => { this.ifr = f; }} styleName='iframe' />
        { 
          tempBlock && spinner() }

          </div>
      </div>
    );
  }
}
