import axios from 'axios';
import { getCustomHeaders } from './utils';
import cookies from 'browser-cookies';
import { cookiesConstants } from '../../../configs/appConfig';

export default {

  changeCompany: data => axios.post('/api/company', data, { headers: getCustomHeaders() }),
  getCompanyMembers: () => axios.get('/api/company/members', { headers: getCustomHeaders() }),
  inviteToCompany: data => axios.post('/api/company/members/add', data, { headers: getCustomHeaders() }),
  deleteFromCompany: data => axios.post('/api/company/members/remove', data, { headers: getCustomHeaders() }),
  deactivateCompanyMember: data => axios.post('/api/company/members/deactivate', data, { headers: getCustomHeaders() }),
  changeCompanyMemberPassword: data => axios.post('/api/company/members/password', data, { headers: getCustomHeaders() }),
  requestJoinCompany: email => axios.get(`/api/company/join?email=${email}`, { headers: getCustomHeaders() }),
  cancelCompanyInviteRequest: () => axios.get(`/api/company/cancel`, { headers: getCustomHeaders() }),
  getClauseLib: () => axios.get(`/api/company/clause`, { headers: getCustomHeaders() }),
  setClauseLib: (clause, isDraft) => axios.post(`/api/company/clause`, 
    {clause, isDraft}, { headers: getCustomHeaders() }),

  getConfig: () => axios.get('/api/cfg', { headers: getCustomHeaders() }),
  updateConfig: () => axios.post('/api/cfg', data, { headers: getCustomHeaders() }),
  getProjectList: () => axios.get('/api/projects/documents', { headers: getCustomHeaders() }),
  getProjectDocList: () => axios.get('/api/projects/documents', { headers: getCustomHeaders() }),
  addProject: data => axios.post('/api/projects', data, { headers: getCustomHeaders() }),
  updateProject: (projectId, newName, admins = null, signee = null) => axios.post('/api/projects/update', { projectId, newName, admins, signee }, { headers: getCustomHeaders() }),
  sendInvitationProject: (userGroup, projectId, id = null) => axios.get(`/api/projects/sendinvites`, { params: {userGroup, projectId, id}, headers: getCustomHeaders() }),

  deleteProject: projectId => axios.delete('/api/projects', { headers: getCustomHeaders(), data: { projectId } }),
  getProjectExport: projectId => axios.get('/api/projects/export', { params: { projectId }, headers: getCustomHeaders() }),

  inviteToProject: (projectId, checkedUsers, addedUsers, deletedExternals, deletedInternals, proved, provedAdded, canTeam) => {
    return axios.post('/api/projects/invite', { projectId, addedUsers, checkedUsers, deletedExternals, deletedInternals, proved, provedAdded, canTeam }, { headers: getCustomHeaders() });
    // return Promise.resolve();
  },

  newDemo: (kind) => axios.get(`/api/documents/newDemo`, { params: { kind }, headers: getCustomHeaders() }),
  getActualDocument: (documentId, projectId) => axios.get(`/api/documents/${documentId}`, { params: { projectId }, headers: getCustomHeaders() }),
  getHistoryDocument: (documentId, historyDocumentId, projectId) => axios.get(`/api/documents/${documentId}/history/${historyDocumentId}`, { params: { projectId }, headers: getCustomHeaders() }),
  addDocument: data => axios.post('/api/documents', data, { headers: getCustomHeaders() }),
  importDocument: data => axios.post('/api/documents/import', data, { headers: getCustomHeaders() }),
  addDocumentTemplate: data => axios.post('/api/documents/template', data, { headers: getCustomHeaders() }),
  renameDocument: (documentId, newName) => axios.put(`/api/documents/${documentId}/`, { newName }, { headers: getCustomHeaders() }),
  deleteDocument: documentId => axios.delete(`/api/documents/${documentId}`, { headers: getCustomHeaders() }),
  chgDocument: (documentId, data) => axios.post(`/api/documents/${documentId}/chg`, data, { headers: getCustomHeaders() }),
  foldDocument: (documentId, newName, parentDoc) => axios.post(`/api/documents/${documentId}/fold`, {newName, parentDoc}, { headers: getCustomHeaders() }),
  trapDocument: (documentId, data) => axios.post(`/api/documents/${documentId}/trap`, data, { headers: getCustomHeaders() }),
  touchDocument: (documentId, data) => axios.post(`/api/documents/${documentId}/touch`, data, { headers: getCustomHeaders() }),
  lockDocument: (documentId, data) => axios.post(`/api/documents/${documentId}/lock`, data, { headers: getCustomHeaders() }),
  copyDocument: (projectId, documentId, version, subVersion) => axios.post('/api/documents/copy', {projectId, documentId, version, subVersion}, { headers: getCustomHeaders() }),
  getDocStats: (documentId) => axios.get(`/api/documents/${documentId}/stats`, { headers: getCustomHeaders() }),
  createDocumentFromTemplate: (projectId, templateId, title) => axios.post(`/api/documents/template`, {projectId, templateId, title},{ headers: getCustomHeaders() }),
  fts: (searchString, ids) => axios.post(`/api/documents/fts`, {searchString, ids}, { headers: getCustomHeaders() }),

  saveStats: data => axios.post(`/api/cfg/savestat`, data,{ headers: getCustomHeaders() }),

  setDocumentViewed: (documentId, projectId) => axios.post(`/api/documents/${documentId}/read`, { projectId }, { headers: getCustomHeaders() }),

  newDocumentVersion: (documentId, projectId, toFreeze) => axios.post(`/api/documents/${documentId}/version`, { projectId, toFreeze }, { headers: getCustomHeaders() }),
  returnDocumentVersion: (documentId, projectId) => axios.post(`/api/documents/${documentId}/oldversion`, { projectId }, { headers: getCustomHeaders() }),
  askRequestDocumentEditingRight: (documentId, projectId) => axios.get(`/api/documents/${documentId}/request`, { params: { projectId }, headers: getCustomHeaders() }),
  requestDocumentEditingRight: (documentId, projectId, confirmed = true, isCollaborate = false, unhideFirstVersion = false, toFinalize = false, isFinalizeRefuse = false, cancelFinalize = false) => axios.post(`/api/documents/${documentId}/request`, { projectId, confirmed, isCollaborate, unhideFirstVersion, toFinalize, isFinalizeRefuse, cancelFinalize }, { headers: getCustomHeaders() }),
  approveDocument: (documentId, approved) => axios.post(`/api/documents/${documentId}/approve`, { approved }, { headers: getCustomHeaders() }),
  forceDocumentEditingRight: (documentId, projectId, confirmed = true) => axios.post(`/api/documents/${documentId}/byForce`, { projectId, confirmed }, { headers: getCustomHeaders() }),
  getPdf: () => axios.get('/api/documents/getpdf', { headers: getCustomHeaders() }),
  downloadDocument: (documentId, projectId, version, subVersion, format, isHistory) => axios.post('/api/documents/get_download_url', { documentId, projectId, version, subVersion, format, isHistory }, { headers: getCustomHeaders() }),
  downloadSignedDocument: (documentId, projectId) => axios.post('/api/documents/get_signed_url', { documentId, projectId }, { headers: getCustomHeaders() }),
  
  signDocumentCheck: (envelopeId, event, documentId, projectId) => 
    axios.get(`/api/documents/signCheck`, { params: {envelopeId, event, documentId, projectId}, headers: getCustomHeaders() }),

  getParagraphList: (documentId, projectId, block) => axios.get(`/api/documents/getdoc/${projectId}/${documentId}/${block}`, {headers: getCustomHeaders() }),

  getUserInfo: () => axios.get('/api/users/me', { headers: getCustomHeaders() }),
  dropUserFirstTime: (kind) => axios.get(`/api/users/firsttime/${kind}`, { headers: getCustomHeaders() }),  
  getUserByMail: data => axios.get('/api/users/frommail', { params: data, headers: getCustomHeaders() }),
  getCandidates: () => axios.get('/api/users/candidates', { headers: getCustomHeaders() }),
  getActivatedUsersList: () => axios.get('/api/users/activated', { headers: getCustomHeaders() }),
  updateUserInfo2: data => axios.put('/api/users/me', data, { headers: getCustomHeaders() }),
  updateUserInfo: data => {
    let headers = {};
    headers[cookiesConstants.COOKIE_HEADER_KEY] = cookies.get(cookiesConstants.COOKIE_TOKEN_KEY);
    headers['Content-Type'] = 'multipart/form-data';

    return axios.post('/api/users/me', data, { headers });
  },
  updateUnregisteredUserInfo: (oldData, newData, projectId) => axios.post('/api/users/update', {oldData, newData, projectId}, { headers: getCustomHeaders() }),
  setUserDateData: data => axios.put('/api/users/setdate', {dateData: JSON.stringify(data)}, { headers: getCustomHeaders() }),
  
  renewSubscription: (token, variant, sum) => axios.post('/api/users/renewSubscription', { token, variant, sum }, { headers: getCustomHeaders() }),
  findUnknownUsers: (list) => axios.post('/api/users/unknown', { list }, { headers: getCustomHeaders() }),

  getUserGroup: data => axios.get('/api/groups', { params: data, headers: getCustomHeaders() }),

  sendComment: data => axios.post(`/api/comments`, data, { headers: getCustomHeaders() }),
  unreadMarks: (id, list) => axios.post(`/api/comments/marks/${id}`, {list}, { headers: getCustomHeaders() }),
  unreadTasks: (id, commentId, group) => axios.post(`/api/comments/tasks/${id}`, {commentId, group}, { headers: getCustomHeaders() }),

  logIn: data => axios.post('/api/auth/login', data),
  signUp: data => axios.post('/api/auth/register', data),
  schedule: data => axios.post('/api/auth/schedule', data),
  checkLogin: token => axios.get(`/api/auth/check/${token}`),
  logout: () => axios.get('/api/auth/logout', { headers: getCustomHeaders() }),
  validateInvitationLink: mailToken => axios.get(`api/auth/register/validate-link/${mailToken}`),
  sendEmailToResetPassword: email => {
    return axios.post('/api/auth/send-reset-password-email', { email }, { headers: getCustomHeaders() });

  },
  resetPassword: (password, confirmPassword, token) => {
    return axios.post('/api/auth/reset-password', { password, confirmPassword, token }, { headers: getCustomHeaders() });
  },

  addMessage: (type, projectId, content, documentId, recipients, group, isPublic, event) => axios.post('/api/message/add', {type, projectId, content, documentId, recipients, group, isPublic, event}, {headers: getCustomHeaders()}),
  replyMessage: (messageId, content, group) => axios.post('/api/message/reply', {messageId, content, group}, {headers: getCustomHeaders()}),
  editMessage: (messageId, content, replyWhen) => axios.post('/api/message/edit', {messageId, content, replyWhen}, {headers: getCustomHeaders()}),
  deleteMessage: (messageId, replyWhen) => axios.post('/api/message/delete', {messageId, replyWhen}, {headers: getCustomHeaders()}),
  resolveMessage: (messageId, resolve) => axios.post('/api/message/resolve', {messageId, resolve}, {headers: getCustomHeaders()}),
  unreadCheckMessage: (messageId, replyWhen) => axios.post('/api/message/unread', {messageId, replyWhen}, {headers: getCustomHeaders()}),
  changeTitle: (messageId, title) => axios.post('/api/message/title', {messageId, title}, {headers: getCustomHeaders()}),
  publishMessage: (messageId, replyWhen) => axios.post('/api/message/publish', {messageId, replyWhen}, {headers: getCustomHeaders()}),

  immediateStart: () => axios.post('/api/auth/immediate', {}, { headers: getCustomHeaders() }),
  temporaryUserLogin: data => axios.post('/api/auth/logintemp', data, { headers: getCustomHeaders() }),
  temporaryUserRegister: data => axios.post('/api/auth/registertemp', data,{ headers: getCustomHeaders() }),

  setWritingCoverMessage: data => axios.put('/api/message/setwritingcovermessage', data,{ headers: getCustomHeaders() }),

  getUserInterfaceData: () => axios.get('/api/users/interfacedata', { headers: getCustomHeaders() }),
  saveUserInterfaceData: data => navigator.sendBeacon('/api/users/interfacedata', new Blob([JSON.stringify({data})], {type: 'application/json'})),

  addTemplate: data => axios.post('/api/templates', data, { headers: getCustomHeaders() }),
  deleteTemplate: id => axios.delete('/api/templates/' + id, { headers: getCustomHeaders() }),
  getTemplatesList: () => axios.get('/api/templates', { headers: getCustomHeaders() }),
  getFinalizedTemplatesList: () => axios.get('/api/templates/finalized', { headers: getCustomHeaders() }),
  updateTemplate: (id, title, folder, description, team) => axios.put('/api/templates', {id, title, folder, description, team},{ headers: getCustomHeaders() }),
  publishTemplate: (templateId, core) => axios.put(`/api/templates/publish/${templateId}`,{core},{ headers: getCustomHeaders() }),
  unpublishTemplate: (templateId) => axios.put(`/api/templates/unpublish/${templateId}`,{},{ headers: getCustomHeaders() }),

// not used
  addVariable: data => axios.post('/api/variables', data, { headers: getCustomHeaders() }),
  getVariables: (templateId) => axios.get('/api/variables/' + templateId, { headers: getCustomHeaders() }),
  updateVariable: (data) => axios.put('/api/variables', data,{ headers: getCustomHeaders() }),
  deleteVariable: (variableId) => axios.delete('/api/variables/' + variableId, { headers: getCustomHeaders() }),
};
