import React, { useEffect, useState } from 'react';
import CSSModules from 'react-css-modules';
import styles from "./sizeChanger.css"
import { transS } from '../../../../services/helpers/lang';

const SizeChanger = ({defaultValue = 0, type = "pt", spacingType, onClick}) => {

    const [value, setValue] = useState(defaultValue)

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue]) // Позже нужно будет убрать, так как вся информация будет передаваться во фрейм

    let changerClass = "changeIndent bordered"
    if (type == "cm") {
        changerClass += " cm"
    }

    return (
        <div styleName='flexRow'>
            <div styleName={changerClass}>
                <span styleName="sign minus clickable"
                    onClick={() =>{setValue((prev) => {
                        const result = (type === 'pt' ? prev - 6 : prev - 0.1).toFixed(1)
                        onClick(spacingType, +result >= 0 ? +result : 0) // Иногда вылетает ошибка из-за излишнего перерендера (2 состояния конфликтуюты). На работоспособность не влияет, но нужно будет поправить, когда появится сервер
                        return +result >= 0 ? +result : 0
                    })}}>-</span>
                    {value}
                {type && <span styleName="ptClass">{transS(type)}</span>}
                <span styleName="sign plus clickable"
                    onClick={() =>{setValue((prev) => {
                        const result = (type === 'pt' ? prev + 6 : prev + 0.1).toFixed(1)
                        onClick(spacingType, +result)
                        return +result
                    })}}>+</span>
            </div>  
        </div>
    );
};

export default new CSSModules(SizeChanger, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});