import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ParagraphList from './views/paragraphList/ParagraphList';
import ViewDoc from './views//paragraphList/ViewDoc';
import ProjectList from './views/projectList/ProjectList';
import PageNotFound from '../pageNotFound/PageNotFound';
import PubSubServer from '../../services/PubSub/PubSub';
import TopMenu from "./components/topMenu/topMenu";
import CompanySettings from "./views/companySettings/companySettings"
import {TempUserView} from "./views/tempUserView/TempUserView"
import TermsUse from "../publicArea/legal/TermsUse"
import DataProtection from "../publicArea/legal/DataProtection"
import DataProtectionEU from "../publicArea/legal/DataProtectionEU"
import DataProtectionGlobal from "../publicArea/legal/DataProtectionGlobal"
import HelpMenu from './components/help/HelpMenu'
import TemplatesList from "./views/TemplatesList/TemplatesList";
import ClauseLib from './views/clauseLib/ClauseLib';

class PrivateArea extends Component {
  constructor (props) {
    super(props);
  }

  onClickArea = () => {
    let event = new Event('clickOnArea');
    window.dispatchEvent(event);
  }

  render () {
    const isTempUser = this.props.user.temporary
    return (
      <div onMouseDown={this.onClickArea} style={{position: 'relative', padding: '0 40px', minHeight: '100vh'}}>
        <TopMenu />
            <HelpMenu />
            <Switch>
              <Route exact path='/legal/terms-of-use' component={TermsUse} />
              <Route exact path='/legal/data-protection' component={DataProtection} />
              <Route exact path='/legal/data-protection/eu' component={DataProtectionEU} />
              <Route exact path='/legal/data-protection/global' component={DataProtectionGlobal} />
              {isTempUser && <Route exact path='/:projectId/documents/:documentId' component={ParagraphList}/>}
              {isTempUser && <Redirect exact from='/:projectId/documents' to='/'/>}
              {isTempUser && <Route path='/' component={TempUserView}/>}
              <Route exact path='/' component={ProjectList}/>
              <Route exact path='/#/:projectId' component={ProjectList}/>
              <Redirect from="/login" to={`/${window.location.search ? '#' + window.location.search : ''}`}/>
              <Redirect from="/new" to={`/#?redirect=newProject`}/>
              {/* <Route exact path='/login' component={ProjectList} /> */}
              {/* <Route exact path='/projects/:projectId' component={ParagraphList} /> */}
              <Route exact path='/sign-in' component={ProjectList}/>
              <Route exact path='/company' component={CompanySettings}/>
              <Route exact path='/templates' component={TemplatesList}/>
              <Route exact path='/clauses' component={ClauseLib}/>
              <Route exact path='/:projectId/documents' component={ProjectList}/>
              <Route exact path='/template/:templateId' component={ParagraphList}/>
              <Route exact path='/:projectId/documents/:documentId' component={ParagraphList}/>
              <Route exact path='/:projectId/docview/:documentId/:selector/:docName' component={ViewDoc}/>
              <Route exact path='/:projectId/documents/:documentId/:itemId' component={ParagraphList}/>
              <Route exact path='/:projectId/documents/:documentId/history/:historyDocumentId' component={ParagraphList}/>
              <Route exact path='/:projectId/documents/:documentId/history/:historyDocumentId/:itemId' component={ParagraphList}/>
              <Route exact path='/not-found/:error' component={PageNotFound}/>
              {/*<Route path='*' component={PageNotFound}/>*/}
            </Switch>
      </div>
    );
  }
};

export default PrivateArea;

export const pubSub = new PubSubServer();
