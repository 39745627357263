import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga';
import history from './history';
import * as reducers from '../reducers';
import rootSaga from './saga';
import { loadState, saveState } from './localStorage';
import { userConstants } from '../constants';
// const routerHistory = routerMiddleware(history);
export const sagaMiddleware = createSagaMiddleware();
const appReducer = combineReducers({
  ...reducers,
  router: connectRouter(history)
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT_USER) {

    window.socket.close();
    window.socket.connect();
    //dispatch(push(`/`));
    state = {
      userReducer: {
        isLoaded: true
      }
    };
  }

  return appReducer(state, action);
};

const persistedState = loadState();

const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25})
      : compose

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(sagaMiddleware, thunk, routerMiddleware(history)))
);

store.subscribe(() => {
  saveState({
    projectReducer: store.getState().projectReducer,
    document: store.getState().document
  });
});

export default store;

sagaMiddleware.run(rootSaga);
