import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'
import useEvent from '../../../../../../services/helpers/use-event';
import ScrollBar from "../../../../../../components/scrollBar/ScrollBar";
import Formatting from '../../../../views/paragraphList/Formatting';
import { turnCommunicationVis } from '../../../../../../redux/paragraph/actions';
import DocComm from '../../../../views/paragraphList/DocComm';
import { transS } from '../../../../../../services/helpers/lang';
import ClauseLibUser from '../../../../views/clauseLib/ClauseLibUser';

const DocControlsUnderMenu = ({ hideVars }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null)
  const {clauseLib} = useSelector(state => state.userReducer)
  const {frameProgress, communicationVis} = useSelector(state => state.paragraphReducer);
  const {formatable, fonts} = useSelector(state => state.document)
  const [documentMessages, setDocumentMessages] =useState([])
  const [communicationNewMessages, setCommunicationNewMessages] = useState(false)
  const [commentsNewMessages, setCommentsNewMessages] = useState(false)
  const [freshComments, setFreshComments] = useState([])
  const [commentsType, setCommentsType] = useState("Text")
  
  const handleResize = (_) => {
    if (!containerRef?.current) return
    let top = containerRef.current.getBoundingClientRect().top;
    if (containerRef?.current?.style)
      containerRef.current.style.height = communicationVis ?
        (window.innerHeight - top) + 'px' : '0px'
         
  }; 
  useEvent('resize', handleResize);
  useEffect(() => handleResize(), [communicationVis]);
  const sendToFrame = frameProgress?.sendToFrame

  const toggleVis = val => {
    sendToFrame({c1: 'communication', communicationVis: val})
    dispatch(turnCommunicationVis(val))
  }

  return (
    <div styleName="container">
      <div styleName="varContainerExtAdapter">
      <div styleName="varContainerExt">
        {!!sendToFrame ? <div styleName="toggles">
          <div styleName={"toggle"+ (communicationVis === 0 || communicationVis === 1 ? " _sel" : "")
               +((communicationVis !== 0 && commentsNewMessages) ? " _new" : "")}
            onClick={() => {
              toggleVis(0);
              setCommentsType("Text")
              setFreshComments([]);
            }}>
            {transS('Comments')}</div>
          {/* <div styleName={"toggle"+ (communicationVis === 1 ? " _sel" : "")
              + ((communicationVis !== 1 && communicationNewMessages) ? " _new" : "")}
            onClick={() => {
              toggleVis(1);
            }}>
             {transS('Communication')}
          </div> */}
          {!!clauseLib?.clauses.length && <div 
              styleName={"toggle"
                + (communicationVis === 3 ? " _sel" : "") }
              onClick={() => {
                toggleVis(3);
              }}>
            {transS('Clause Library')}            
          </div>
          }
          <div 
              styleName={"toggle"
                + (communicationVis === 2 ? " _sel" : "") 
                + (formatable ? "" : " _unsel")}
              onClick={() => {
                formatable && toggleVis(2);
              }}>
            {transS('Formatting')}            
          </div> 
            {(communicationVis === 0 || communicationVis === 1) && 
            <div styleName="commentsAnimationContainer">
              <div styleName="commentsTypeContainer">
                <div styleName={"commentsType" + (commentsType === "Text" ? " active" : "")} onClick={() => {setCommentsType("Text"); toggleVis(0)}}>{transS("For text")}</div>
                <div styleName={"commentsType" + (commentsType === "All" ? " active" : "")} onClick={() => {setCommentsType("All"); toggleVis(1)}}>{transS("For all document")}</div>
              </div>
            </div>
            }
          </div> 
          : 
          <div styleName="toggles"></div>}
        {!!communicationVis && <div styleName="scrollContent" ref={containerRef}> 
        <ScrollBar
          style={{
            height: "100%",
            width: "463px",
            zIndex: "10",
            backgroundColor: "#ffffff",
          }}
          invisible="true"
        >
        {communicationVis === 2 && <Formatting />}
        {communicationVis === 3 && <ClauseLibUser />}
        {communicationVis === 1 && <DocComm />}
        </ScrollBar>
        </div>}
      </div>
    </div>
    </div>
  );
  }
  
  export default CSSModules(DocControlsUnderMenu, styles, {allowMultiple: true})